@import "../../../styles/variables";

.WiltTimeline {
  padding: 8px;

  @media (min-width: $tablet-width) {
    padding: 24px;
  }

  .title {
    font-weight: normal;
  }

  .body {
    padding-top: 16px;

    .timeline {

      .wiltItem {
        padding-bottom: 24px;

        .indexContainer {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .index {
            font-size: 18px;
            color: $tertiaryTextColor;
            font-weight: 800;
          }

        }

        .wiltContainer {
          display: flex;
          align-items: flex-start;

          .wiltContent {
            flex: 1;
            //padding-left: 8px;

            .wiltTitleInput {
              font-weight: normal;
            }

            .wiltTitle {
              margin-top: 4px;
              font-size: 21px;
              font-weight: 600;
              color: black !important;
              letter-spacing: 1px;
              line-height: 24px;

              @media (min-width: $tablet-width) {
                font-size: 24px;
              }

              > span {
                color: black !important;
              }

              .ant-typography-edit {
                display: none !important;
              }

              .anticon {
                display: none !important;
              }
            }

            .wiltBody {
              margin-top: 8px;
              color: rgba(0, 0, 0, 0.84);
              letter-spacing: 1px;
              font-size: 18px;
              line-height: 24px;

              @media (min-width: $tablet-width) {
                font-size: 21px;
              }

              > span {
                color: rgba(0, 0, 0, 0.84);
              }
            }
          }

          .wiltEdit {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          }
        }

      }
    }

    .addWiltItem {
      padding-top: 8px;
      display: flex;
      justify-content: space-between;

      .addWiltItemButton {
        font-weight: normal;
      }
    }
  }

}
