@import "../../styles/variables";

.SportEventScreen {
  @include centered;
  width: 100%;

  @media (min-width: $tablet-width) {
    width: $content-width;
  }

  .container {
    padding-top: 16px;

    .event {

      .titleContainer {
        text-align: center;

        .title {
          font-weight: bold;
        }
      }

      .teams {
        padding-top: 16px;
        display: flex;
        align-items: center;

        .teamContainer {
          flex: 1;
          display: flex;
          justify-content: center;
          text-align: center;
          width: fit-content;

          .team {

            .image {
              width: 100px;
              height: 100px;
              border-radius: 50px;
            }

            .name {
              margin-top: 16px;
              font-size: 16px;
              font-weight: bold;
            }
          }

          .rightScore {
            margin-top: auto;
            margin-bottom: auto;
            margin-left: 24px;
            width: 60px;
            font-size: 24px;
            font-weight: 600;
          }

          .leftScore {
            margin-top: auto;
            margin-bottom: auto;
            margin-right: 24px;
            width: 60px;
            font-size: 24px;
            font-weight: 600;
          }
        }

      }

      .actions {
        text-align: center;
        margin-top: 16px;
      }
    }

    .content {
      padding-bottom: 32px;

      .stadium {
        display: flex;
        height: 400px;
        margin-top: 16px;
        padding-left: 32px;
        padding-right: 32px;

        .leftStadium {
          flex: 1;
          background: #154734;
          border-top-left-radius: 100px;
          border-bottom-left-radius: 100px;
          display: flex;
          align-items: center;

          .innerLeft {
            background: white;
            height: 240px;
            width: 100%;
            margin-left: 32%;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;

            .support {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              height: 100%;

              .supportImage {
                width: 80px;
                height: 80px;
                border-radius: 50%;
              }

              .supportTitle {
                margin-top: 8px;
              }

              .enter {
                margin-top: 8px;
              }
            }
          }
        }

        .rightStadium {
          flex: 1;
          background: #002248;
          border-top-right-radius: 100px;
          border-bottom-right-radius: 100px;
          display: flex;
          align-items: center;

          .innerRight {
            background: white;
            height: 240px;
            width: 100%;
            margin-right: 32%;
            text-align: center;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;

            .support {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              height: 100%;

              .supportImage {
                width: 80px;
                height: 80px;
                border-radius: 50%;
              }

              .supportTitle {
                margin-top: 8px;
              }

              .enter {
                margin-top: 8px;
              }
            }

          }
        }
      }

    }
  }

}
