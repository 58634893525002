@import "../../styles/variables";

.NewChatScreen {

  .headerContainer {
    max-width: $content-width;
    margin-left: auto;
    margin-right: auto;
    position: sticky;
    top: 65px;
    z-index: 1;

    .headerWhiteboard {
      background: var(--main-background-color);

      @media (min-width: $tablet-width) {
        padding-top: 8px;
      }

      .messageHeader {
        @include border-around-element;
        padding: 12px 16px;

        @media (min-width: $tablet-width) {
          margin-top: 12px;
        }

        .topHeader {
          display: flex;
          align-items: center;

          .titleHeader {
            font-size: 18px;
            font-weight: bold;
            color: black;
            margin-left: 8px;
          }
        }

        .searchContainer {
          margin-top: 8px;
        }
      }

      .createChatGroup {
        @include border-around-element;
        padding: 16px;
        margin-top: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .leftContent {
          display: flex;
          align-items: center;

          .createIcon {
            font-size: 24px;
          }

          .createTitle {
            margin-left: 16px;
          }
        }

        .rightContent {
          .rightArrow {
            font-size: 24px;
          }
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

  }

  .container {
    max-width: $content-width;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 16px;
  }
}
