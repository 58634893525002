@import "../../../styles/variables";

.DraftsGrid {

  .gridList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 100vw * 0.5;
    grid-column-gap: 0.5vw;
    grid-row-gap: 0.5vw;
    padding-top: 12px;
    padding-bottom: 16px;
    min-height: 400px !important;
    height: auto !important;

    @media (min-width: $tablet-width) {
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: 264px;
    }

    .skeletonItem {
      grid-column: span 1;
      width: 100%;
      height: 100%;
      bottom: 0;
    }
  }

  .emptyView {
    grid-column: span 2;
    @media (min-width: $tablet-width) {
      grid-column: span 3;
    }
  }
}
