@import "../../../styles/variables";

.InterestMediumCard2 {
  border-color: lightgrey;
  border-style: solid;
  border-width: 1px;
  padding: 8px 4px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin: 4px;

  .mediumContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .image {
      width: auto;
      height: 100px;
      border-color: lightgrey;
      border-style: solid;
      border-width: 1px;
    }

    .mediumTitle {
      text-align: center;
      margin-top: 8px;
      font-size: 12px;
      padding-left: 4px;
      height: 50px;
      //color: black;
      overflow-y: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .actions {
    margin-top: 8px;
    text-align: center;

    .selectButton {
      font-weight: normal;
    }

    .selected {
      background: $green;
      border-color: $green;
    }
  }

}
