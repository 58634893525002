@import "../../../styles/variables";

.CustomizeQuote {

  .quoteContainer {
    display: flex;
    justify-content: center;
    border-bottom-color: lightgrey;
    border-bottom-width: 1px;
    border-bottom-style: solid;

    .layersContent {
      $mobileWidth: 100vw;
      height: $mobileWidth;
      width: $mobileWidth;
      border-left-style: solid;
      border-left-color: lightgrey;
      border-right-style: solid;
      border-right-color: lightgrey;
      border-left-width: 0px;
      border-right-width: 0px;
      position: relative;

      &:hover {
        cursor: pointer;
      }

      @media (min-width: $mobile-width) {
        $temp: 400px;
        width: 402px;
        height: $temp;
        border-left-width: 1px;
        border-right-width: 1px;
      }

      .quote {
        position: relative;
        width: 100%;
        height: 100%;
      }
    }
  }

  .controls {

    .colors {
      display: flex;
      justify-content: space-around;

      .colorsButtonTitle {
        padding-left: 4px;
      }

      .backgroundColor {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        padding-left: 4px;
        padding-right: 4px;

        .palette {
          width: 20px;
          height: 20px;
          border-radius: 10px;
          border-style: solid;
          border-color: lightgrey;
          border-width: 1px;
        }
      }

      .fontColor {
        display: flex;
        align-items: center;

        .palette {
          width: 20px;
          height: 20px;
          border-radius: 10px;
          border-style: solid;
          border-color: lightgrey;
          border-width: 1px;
        }
      }
    }
  }
}
