
.PodcastEpisodes {


  .emptySkeleton {
    padding-top: 16px;
    padding-bottom: 40px;

    .skeletonItem {
      margin-top: 16px;
    }
  }
}
