@import "../../../../styles/variables";

.PodcastsGridCard {
  grid-column: span 1;
  padding: 0px !important;
  margin: 0px !important;
  width: 100%;
  position: relative;

  .postcontainer {
    overflow: hidden;
    width: 100% !important;
    height: 190px;
    object-fit: cover;

    @media (min-width: $tablet-width) {
      height: 360px;
    }
  }

  .image {
    width: 100% !important;
    //min-height: 150px;
    //height: 100% !important;
    //object-fit: cover;

    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding: 8px;

    &:hover {
      cursor: pointer;
    }

    @media (min-width: $tablet-width) {
      padding: 16px;
    }
  }

  .mediumBackground {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.4;
  }

  .removeContainer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .removeButton {
      border-color: red;
      background: red;
      color: white;
    }
  }
}
