@import "../../styles/variables";

.WiltTitlePreview {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: var(--main-background-color);
  display: flex;
  flex-direction: column;

  &:hover {
    cursor: pointer;

    .wiltBody {
      color: $blue;
    }
  }

  @media (min-width: $tablet-width) {
    //height: 360px;
    @include border-element;
  }

  .wiltHeader {
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 4px;

    @media (min-width: $tablet-width) {
      height: 30px;
    }

    .wiltLabel {
      font-style: italic;
      background: yellow;
      padding-left: 2px;
      padding-right: 2px;
      font-size: 10px;
      color: black;

      @media (min-width: $tablet-width) {
        padding-left: 4px;
        padding-right: 4px;
        font-size: 12px;
      }
    }
  }

  .wiltBody {
    flex: 1;
    display: flex;
    padding-left: 8px;
    padding-right: 8px;
    color: var(--primary-text-color);
    font-size: 12px;
    overflow-y: hidden;

    @media (min-width: $tablet-width) {
      font-size: 16px;
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  .wiltFooter {
    height: 34px;
    display: flex;
    align-items: center;
    padding: 2px 8px 4px;

    @media (min-width: $tablet-width) {
      height: 40px;
      padding: 4px 12px 8px;
    }

    .wiltSourceImage {
      height: 100%;
      object-fit: contain;
    }

    .wiltSourceTitle {
      font-style: italic;
      padding-left: 4px;
      flex: 1;
      font-size: 10px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      color: var(--primary-text-color);

      @media (min-width: $tablet-width) {

      }
    }
  }
}
