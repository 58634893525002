@import "../../../styles/variables";

.SummaryCardSkeleton {
  @include centered;
  max-width: 800px;
  margin: 16px auto;
  padding-left: 16px;
  padding-right: 16px;

  @media (min-width: $tablet-width) {
    @include border-around-element;
  }
}
