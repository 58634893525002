@import "../../styles/variables";

.RatingItemPodcastHome {
  padding: 16px;

  .container {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;

    .leftContainer {
      padding-right: 16px;

      @media (min-width: $tablet-width) {
        padding-right: 0px;
        padding-left: 32px;
      }

      .ratingProgress {
        height: 100%;

        .ratingValue {
          font-size: 40px;
          color: var(--primary-text-color);
          font-weight: 500;
        }
      }
    }

    .rightContainer {
      flex: 1;

      .imageContainer {
        text-align: center;

        .image {
          height: auto;
          width: 162px;

          @media (min-width: $tablet-width) {
            height: auto;
            width: 236px;
          }

        }
      }
    }
  }
}
