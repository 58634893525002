@import "../../styles/variables";

.ClubAboutScreen {

  .container {
    @include centered;
    max-width: $content-width;

    .header {
      display: flex;
      align-items: center;
      margin-top: 16px;

      .backButton {
        display: none;

        @media (min-width: $tablet-width) {
          display: block;
        }
      }

      .title {
        padding-left: 8px;
        font-size: 18px;
        font-weight: 600;
      }
    }

    .body {
      @include border-around-element;
      margin-top: 16px;
      padding: 16px;

      .emptyText {
        font-style: italic;
      }
    }
  }
}
