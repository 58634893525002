@import "../../styles/variables";

.RemindersScreen {

  .container {
    @include centered;
    @include border-around-element;
    max-width: $content-width;
    padding: 16px;

    @media (min-width: $tablet-width) {
      margin-top: 16px;
    }

    .header {
      display: flex;
      align-items: center;

      .title {
        font-size: 16px;
        margin-left: 16px;
      }
    }

    .subtitle {
      margin-top: 16px;
    }

    .form {
      margin-top: 24px;
    }
  }

}
