

.TextModal {

  .textModalBody {

    .textModalContainer {

      .textArea {
        padding: 6px 11px;
        font-size: 16px;
      }
    }

    .actions {
      margin-top: 16px;
      display: flex;
      justify-content: flex-end;
    }

    .saveButton {
      font-weight: normal;
    }
  }
}
