@import "../../styles/variables";

.CreateProgress {
  width: 100%;
  //background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: lightgrey;
  padding: 8px;

  @media (min-width: $tablet-width) {
    //width: calc(100vw - 16px);
  }

  .progressInfo {
    color: var(--primary-text-color);
    text-align: right;
    text-transform: capitalize;

    .title {
      font-size: 24px;
      font-weight: 600;
    }

    .subtitle {
      font-size: 15px;
    }
  }
}
