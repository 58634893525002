@import "../../../styles/variables";

.PreviewList {
  position: relative;
  //background: red;
  width: 400px;
  height: 400px;
  margin: auto;

  .carousel {
    @include border-around-element;
    //position: absolute;
    height: 100%;
    width: 100%;

    .thumbnailContainer {
      height: 100%;
      width: 100%;

      .thumbnailImage {
        height: 100%;
        width: 100%;
      }
    }

    .previewItem {
      height: 100%;
      width: 100%;
      padding: 16px;
      text-align: center;
      overflow: hidden;

      .image {
        width: 160px;
        height: 220px;
        object-fit: contain;
      }

      .title {
        margin-top: 8px;
        font-size: 16px;
        font-weight: 600;
        color: var(--primary-text-color);
      }

      .description {
        margin-top: 8px;
      }
    }
  }

  .leftContainer {
    position: absolute;
    top: 50%;
    left: 0;
  }

  .rightContainer {
    position: absolute;
    //background: red;
    top: 50%;
    right: 0;
  }

  .icon {
    font-size: 24px;
    color: white;

    &:hover {
      color: $blue;
    }
  }

  .bottomContainer {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
  }

}