@import "../../styles/variables";

.RatingItem {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 8px;
  bottom: 0;
  display: flex;
  flex-direction: column;

  @media (min-width: $tablet-width) {
    padding: 16px;
  }

  .container {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    .leftContainer {
      flex: 8;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .ratingProgress {
        .ratingValue {
          font-size: 22px;
          color: var(--primary-text-color);

          @media (min-width: $tablet-width) {
            font-size: 24px;
          }
        }

        .outOf {
          font-size: 10px;

          @media (min-width: $tablet-width) {
            font-size: 14px;
          }
        }
      }

      .ratingProgressTablet {
        @extend .ratingProgress;
        display: none;

        @media (min-width: $tablet-width) {
          display: initial;
        }
      }

      .ratingProgressMobile {
        @extend .ratingProgress;
        display: initial;

        @media (min-width: $tablet-width) {
          display: none;
        }
      }
    }

    .rightContainer {
      padding-left: 8px;
      flex: 10;
      width: 100%;

      .image {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }

    .topContainer {
      font-size: 12px;

      .title {
        color: var(--primary-text-color);
        font-weight: 500;

        @media (min-width: $tablet-width) {
          font-size: 16px;
        }
      }
    }

    .bottomContainer {
      flex: 1;

      .ratingProgress {
        width: 50px !important;
        display: none;

        @media (min-width: $tablet-width) {
          display: initial;
        }
      }
    }

  }
}
