@import "../../../styles/variables";

.RankingGrid {

  .strokeme {
    //color: black;
    //text-shadow: -1px -1px 0 #696767, 1px -1px 0 #696767, -1px 1px 0 #696767, 1px 1px 0 #696767;
  }

  .list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(160px, auto);
    grid-column-gap: 0;
    grid-row-gap: 0;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: $tablet-width) {
      grid-column-gap: 0.5vw;
      grid-row-gap: 0.5vw;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: minmax(250px, auto);
    }

    .item {
      grid-column: span 1;
      margin: 0px !important;
      width: 100%;
      position: relative;
      border-top-style: solid;
      border-top-width: 0.5px;
      border-top-color: lightgrey;
      border-bottom-color: lightgrey;
      border-bottom-width: 0.5px;
      border-bottom-style: solid;

      .tabsSkeleton {
        width: 100%;
        height: 100%;
        //height: 260px !important;
      }

      &:nth-child(even) {
        border-left-color: lightgrey;
        border-left-style: solid;
        border-left-width: 0.5px;
      }

      &:nth-child(odd) {
        border-right-color: lightgrey;
        border-right-style: solid;
        border-right-width: 0.5px;
      }

      @media (min-width: $tablet-width) {
        border-style: solid;
        border-color: lightgray;
        border-width: 1px;
      }

      &:hover {
        cursor: pointer;
      }

      .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .content {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        padding-right: 8px;
        //justify-content: space-between;

        .number {
          flex: 2;
          font-size: 26vw;
          font-family: "Avenir Next Condensed";
          font-weight: 800;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          letter-spacing: -16px;
          //color: #222121;

          @media (min-width: $tablet-width) {
            font-size: 150px;
          }
        }

        .mediumContainer {
          //background: orange;
          flex: 3;
          display: flex;
          //justify-content: flex-end;

          .mediumImage {
            width: 100%;
            //height: 100%;
            object-fit: contain;
          }
        }
      }

      .imageContent {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        //padding-right: 8px;
        padding: 16px;

        .number {
          flex: 2;
          font-size: 11vw;
          font-weight: 800;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          //background: green;
        }

        .mediumContainer {
          //background: orange;
          flex: 3;
          display: flex;
          //justify-content: flex-end;

          .mediumImage {
            width: 100%;
            //height: 100%;
            object-fit: contain;
          }
        }

      }
    }

    .emptyView {
      grid-column: span 2;

      @media (min-width: $tablet-width) {
        grid-column: span 3;
      }
    }
  }
}
