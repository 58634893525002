@import "../../styles/variables";

.Create {

  .reactions {

    .reactItem {
      font-size: 16px !important;
    }
  }

  @media (min-width: $tablet-width) {
    @include centered;
    //min-height: auto;
    max-width: 500px;
    margin-top: 16px;
    padding-bottom: 16px;
  }

  .container {

    .createList {

      .createCard {
        @include border-bottom-element;
        padding: 8px 16px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-bottom: 0px !important;

        @media (min-width: $tablet-width) {
          padding: 16px;
          @include border-around-element;
          margin-bottom: 16px !important;
          min-height: 120px;
        }

        &:hover {
          border-width: 2px !important;
          border-color: $blue !important;
          border-style: solid !important;
          //box-shadow: 0 1px 2px 0 rgba(0,0,0,.05)!important;
          //box-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06)!important;
          //box-shadow: 0 4px 6px -1px rgba(0,0,0,.1),0 2px 4px -1px rgba(0,0,0,.06)!important;
          cursor: pointer;

          .createImage {
            color: $blue !important;
          }
        }

        .createImage {
          padding-top: 6px;
          font-size: 22px;
        }

        .quote {
          color: $red;
        }

        .template {
          color: $purple;
        }

        .wilt {
          color: $orange;
        }

        .list {
          color: $green;
        }

        .createText {
          padding-left: 16px;
          flex: 1;

          .title {
            color: var(--primary-text-color);
            font-size: 20px;
            font-weight: 500;
            text-transform: capitalize;
          }

          .subtitle {
            //font-size: 16px;
            padding-top: 8px;

            @media (min-width: $tablet-width) {
              padding-top: 16px;
            }
          }
        }
      }
    }
  }
}
