@import "../../../../../styles/variables";

.FoodCard {
  @include border-around-element;
  width: 280px;
  min-height: 200px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;

  .header {
    @include border-bottom-element;
    padding: 8px;
  }

  .body {
    flex: 1;
    padding: 8px;
  }

  .footer {
    padding: 8px;
    @include border-top-element;
  }
}
