@import "../../styles/variables";

.NotificationScreen {
  min-height: calc(100vh - 134px);

  .container {
    padding-top: 16px;
  }

  .spinningContainer {
    margin-top: 16px;
    text-align: center;
  }

  .moreContainer {
    display: flex;
    justify-content: center;
    padding-bottom: 16px;
    padding-top: 16px;

    .moreButton {
      width: 200px;
    }

  }


}

.container {
  padding-top: 16px;

  .requests {
    @include border-around-element;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    .noRequests {
      width: 30px;
      height: 30px;

      .requestCount {
        background: $red;
        color: white;
        width: 100%;
        height: 30px;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .textContainer {
      flex: 1;
      padding-left: 8px;

      .title {
        //color: black;
        font-weight: bold;
      }

      .subtitle {

      }
    }
  }

}
