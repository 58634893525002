@import "../../../styles/variables";

.NewMain {

  .container {
    @media (min-width: $tablet-width) {
      @include centered;
      max-width: $max-width;
      flex-direction: row;
      //padding-left: 16px;
      //padding-right: 16px;
    }
  }
}
