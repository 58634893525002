@import "../../../styles/variables";

.MultipleSelect {

  .title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }

  .selected {
    background: $green !important;
    color: white !important;
    border-color: $green !important;
  }
}
