@import "../../styles/variables";

.BottomNavBar {
  min-height: 69px;
  width: 100%;
  background: var(--main-background-color);
  border-top-color: lightgrey;
  border-top-style: solid;
  border-top-width: 1px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media (min-width: $desktop-width) {
    display: none;
  }

  .icon {
    font-size: 24px;
    color: var(--secondary-text-color);
  }

  .selected {
    color: $blue;
  }

  .selectedImage {
    border-color: $blue;
    border-width: 2px;
    border-style: solid;
  }

  .item {

  }

  .profileImage {
    width: 30px;
    height: 30px;
    border-radius: 15px;
  }

  a {
    color: rgba(0,0,0,0.65) !important;
  }

  .actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;

    .navigationBtn {
      text-transform: capitalize;
    }

    .saveAsDraft {
      text-transform: capitalize;
      margin-right: 16px;
    }
  }
}

.blank {
  background: white;
  border-color: white;
}

.blankWithHeight {
  background: white;
  border-color: white;
  height: 0px;
}

.hide {
  display: none;
}
