@import "../../../styles/variables";

.FriendUserCard {
  display: flex;
  align-items: center;
  padding: 8px 20px;
  border-bottom-style: solid !important;
  border-bottom-width: 1px !important;
  border-bottom-color: lightgrey !important;


  @media (min-width: $tablet-width) {
    @include border-around-element;
    margin-top: 12px;
  }

  &:hover {
    cursor: pointer;
  }

  .image {
    width: 56px;
    height: 56px;
    border-radius: 28px;
    border-style: solid;
    border-width: 1px;
    border-color: lightgrey;
  }

  .textContainer {
    padding-left: 12px;

    .displayName {
      font-weight: 700;
    }

    .username {

    }
  }
}
