@import "../../styles/variables";

.TabLandingIcon {
  width: calc(100vw * 1/4); //width: calc(100vw * 0.1666666);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: $tablet-width) {
    width: calc(#{$max-width} * 1/4);
  }

  .icon {
    width: 18px;
    filter: var(--main-filter);

    @media (min-width: $tablet-width) {
      width: 24px;
    }
  }



  .active {
    filter: invert(73%) sepia(65%) saturate(5192%) hue-rotate(178deg) brightness(93%) contrast(84%);
  }

  .title {
    margin-top: 8px;
    text-transform: capitalize;
  }
}
