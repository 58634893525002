@import "../../../../../styles/variables";

.TopPostCard {
  @include border-around-element;
  width: 280px;
  min-height: 200px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;

  .header {
    @include border-bottom-element;
    padding: 8px;
    display: flex;
    align-items: center;

    .image {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }

    .supporter {
      flex: 1;
      padding-left: 8px;
    }

    .gameTime {

    }
  }

  .body {
    flex: 1;
    padding: 16px 8px;

    .profileHeader {
      display: flex;
      align-items: center;

      .profileImage {
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }

      .username {
        padding-left: 8px;
        font-weight: bold;
      }

    }

    .content {
      margin-top: 4px;
    }
  }
}
