@import "../../../styles/variables";

.QuizPostCard {
  background: var(--main-background-color);
  overflow: hidden;
  @include border-top-bottom;
  margin-bottom: $home-feed-card-margin-bottom !important;
  width: 100%;
  flex-direction: column;

  @media (min-width: $tablet-width) {
    margin: 16px auto;
    border-radius: 4px;
    border-style: solid;
    border-color: lightgrey;
    border-width: 1px;
    border-bottom-width: 1px !important;
  }

  .quizContent {
    text-align: center;
    padding: 24px;

    .quizLabel {
      font-style: italic;
      //background: linear-gradient(180deg,rgba(255,255,255,0) 0%, rgba(255, 255, 0, 0.9) 100%);
      background: yellow;
      padding-left: 2px;
      padding-right: 2px;
      font-size: 12px;
      color: black;
      font-weight: 500;

      @media (min-width: $tablet-width) {
        padding-left: 4px;
        padding-right: 4px;
        font-size: 14px;
      }
    }

    .quizTitle {
      margin-top: 16px;
      font-size: 16px;
      color: var(--primary-text-color);
    }

    .stats {
      margin-top: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      .statContainer {
        display: flex;
        align-items: center;
        margin-left: 8px;
        margin-right: 8px;

        .icon {
          margin-right: 4px;
          color: $purple;
          font-weight: bold !important;
        }

        .barIcon {
          margin-right: 4px;
          color: $orange;
          font-weight: bold !important;
        }
      }
    }

    .startQuizButton {
      margin-top: 24px;
    }
  }
}
