@import "../../../styles/variables";

.InterestCard {
    width: 100%;
    border-style: solid;
    border-width: 1px;
    border-color: lightgrey;
    border-radius: 2px;
    text-align: center;
    overflow: hidden;

    .imageContainer {
        height: 100px;
        overflow: hidden;
        position: relative;

        .backgroundImage {
            object-fit: cover;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
        }

        .darkBackground {
            position: absolute;
            background: black;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0.5;
        }

        .titleContainer {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .title {
                color: white;
                //font-size: 18px;
                font-weight: bold;
                text-transform: uppercase;
                font-size: 16px;
                padding: 2px;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .actions {
        margin-top: 16px;
        margin-bottom: 16px;

        .selectButton {
            font-weight: normal;
        }

        .selected {
            background: $green;
            border-color: $green;
        }
    }
}
