@import "../../styles/variables";

.StorynotesNoteScreen {
  display: flex;
  flex-direction: column;
  align-items: center;

  .noteHeader {
    //min-height: 66px;
    display: flex;
    align-items: center;
    padding: 8px;
    width: 100%;
    max-width: $tablet-width;
    @include border-around-element;

    @media (min-width: $tablet-width) {
      margin-top: 16px;
    }

    .backButton {

    }
  }

  .container {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-width: $tablet-width;
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;

    @media (min-width: $tablet-width) {
      position: relative;
      height: calc(100vh - 172px);
      margin-top: 8px;
      @include border-around-element;
    }

    .header {
      padding: 16px;

      .titleContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
          flex: 1;
          text-align: left;
          font-size: 17px;
          font-weight: bold;
        }
      }

      .subtitle {
        display: flex;

        .categories {
          margin-left: 16px;
          display: flex;

          .category {
            margin-right: 8px;
          }
        }
      }
    }

    .loadingHeader {
      padding: 16px;

      .loadingItem {
        width: 100%;
      }
    }

    .messagesContainer {
      border-top-color: lightgrey;
      border-top-width: 1px;
      border-top-style: solid;
      flex: 1 !important;
      display: flex !important;
      flex-direction: column !important;
      overflow-y: auto !important;
      padding-bottom: 8px;

      .loadingContent {
        padding: 16px;
      }

      .text {
        padding: 16px;
        white-space: pre-line;
      }

      .quoteThoughtContainer {
        padding: 16px;
        display: flex;
        flex-direction: column;

        @media (min-width: $tablet-width) {
          flex-direction: row;
        }

        .quoteContainer {
          flex: 1;
          margin-right: 16px;
          margin-left: 16px;

          .title {
            font-weight: 600;
            text-transform: capitalize;
            text-decoration: underline;
          }

          .thoughtTitle {
            @extend .title;
            margin-top: 24px;

            @media (min-width: $tablet-width) {
              margin-top: 0px;
            }
          }

          .body {
            margin-top: 16px;
            white-space: pre-line;
          }
        }
      }

      .questionAnswerContainer {
        padding: 16px;

        .questionContainer {
          .title {
            font-weight: 600;
            text-transform: capitalize;
            text-decoration: underline;
          }

          .body {
            margin-top: 16px;
            white-space: pre-line;
          }
        }

        .answerContainer {
          margin-top: 16px;

          .showAnswer {
            text-transform: capitalize;
          }

          .title {
            font-weight: 600;
            text-transform: capitalize;
            text-decoration: underline;
          }

          .body {
            margin-top: 16px;
            white-space: pre-line;
          }
        }
      }

      .spinningContainer {
        text-align: center;
        padding-top: 16px;
      }

      //.item {
      //  //background: orange;
      //  height: auto;
      //}
    }

    //.inputContainer {
    //  min-height: 50px;
    //  border-top-color: lightgrey;
    //  border-top-width: 1px;
    //  border-top-style: solid;
    //  height: auto;
    //  display: flex;
    //  align-items: center;
    //  padding: 4px 8px;
    //
    //}
  }
}
