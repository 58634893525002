@import "../../../styles/variables";

.SourceCard {
  @include border-around-element;
  padding: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;

  @media (min-width: $tablet-width) {
    margin-top: 16px;
  }

  .sourceImage {
    width: 40px;
    //height: 40px;
    border-radius: 20px;
  }

  .sourceLink {
    padding-left: 8px;
    text-decoration: underline;
    color: $blue;

    &:hover {
      cursor: pointer;
    }
  }
}
