@import "../../styles/variables";

.UsersScreen {

  .spinningContainer {
    margin-top: 16px;
    text-align: center;
  }

  .container {
    @include centered;
    width: $content-width;
    margin-top: 16px;
  }
}
