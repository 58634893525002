@import "../../../styles/variables";

.ClubCard {
  position: relative;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: lightgrey;
  overflow: hidden;

  @media (min-width: $tablet-width) {
    @include border-around-element;
  }

  &:hover {
    cursor: pointer;
  }

  .backgroundContainer {
    position: absolute;
    width: 100%;
    height: 100%;

    .clubInfoContainer {
      padding: 16px;
      display: flex;
      flex-direction: column;
      height: 100%;

      .body {
        flex: 1;

        .imageAndInfoHeader {
          display: flex;
          justify-content: space-between;

          .image {
            width: 56px;
            height: 56px;
            border-radius: 3px;

            @media (min-width: $tablet-width) {
              width: 64px;
              height: 64px;
            }
          }

          .info {

            .unfilledStar {
              font-size: 18px;
              color: var(--primary-text-color);
            }

            .filledStar {
              font-size: 18px;
              color: $logoYellow;
            }
          }

        }

        .title {
          margin-top: 8px;
          font-weight: 700;
          font-size: 16px;
          margin-bottom: 0px !important;
        }
      }

      .footer {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
