@import "../../../styles/variables";

.Footer {
  background: var(--main-background-color);;
  height: 160px;
  padding-bottom: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  .instagram {
    margin-left: 16px;
  }

  .copyright {
    margin-top: 16px;
  }
}