@import "../../../styles/variables";

.MediumModal {

  .content {

    .resultContainer {
      margin-top: 16px;

      .resultItem {
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-bottom-color: $backgroundGray;
        padding-top: 8px;
        padding-bottom: 8px;
        color: $blue;

        &:last-child {
          border-width: 0px;
        }

        &:hover {
          background: $backgroundGray;
          cursor: pointer;
        }
      }
    }
  }
}
