@import "../../styles/variables";

.MessagesScreen {

  .headerContainer {
    max-width: $content-width;
    margin-left: auto;
    margin-right: auto;
    position: sticky;
    top: 65px;
    z-index: 1;

    .headerWhiteboard {
      background: var(--main-background-color);

      @media (min-width: $tablet-width) {
        padding-top: 8px;
      }

      .messageHeader {
        @include border-around-element;
        padding: 12px 16px;

        @media (min-width: $tablet-width) {
          margin-top: 12px;
        }

        .topHeader {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .titleHeader {
            font-size: 18px;
            font-weight: bold;
            color: black;
          }
        }

        .searchContainer {
          margin-top: 8px;
        }
      }

      .messageRequest {
        @include border-around-element;
        padding: 8px 16px;
        margin-top: 8px;
        display: none;
      }
    }

  }

  .container {
    max-width: $content-width;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 16px;
  }
}
