@import "../../styles/variables";

.EditGrid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 0px;

  @media (min-width: $tablet-width) {
    padding: 8px 24px 0px;
  }

  .leftContent {
    margin-bottom: 8px !important;

    .mediumType {
      font-weight: 600;
      text-transform: capitalize;
    }

    @media (min-width: $tablet-width) {
      margin-bottom: 0px !important;
    }

    .viewTop {
      text-transform: capitalize;
    }
  }

  .rightContent {
    margin-bottom: 8px !important;

    @media (min-width: $tablet-width) {
      margin-bottom: 0px !important;
    }

    .viewTop {
      text-transform: capitalize;
    }

    .editButton {
      margin-left: 8px;
    }

  }
}

.filterDrawer {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;

  .filterSection {
    margin-bottom: 16px;

    .filterTitle {
      //color: $secondaryTextColor;
      font-size: 12px;
      text-transform: uppercase;
    }

    .filterValues {
      margin-top: 4px;

      .radioItem {
        display: block;
        height: 40px;
        line-height: 40px;
        font-weight: 600;
      }
    }
  }
}
