@import "../../styles/variables";

.StandsScreen {
  padding-top: 16px;

  .container {
    @include centered;
    @include border-around-element;
    max-width: $content-width;
    padding-bottom: 16px;

    .headerContainer {
      padding: 16px;

      .scoreHeader {
        display: flex;
        align-items: center;

        .standTitle {
          flex: 1;
          font-weight: bold;
          text-transform: lowercase;
        }

        .scoreContainer {
          display: flex;
          align-items: center;
          justify-content: center;

          .teamIcon1 {
            width: 30px;
            height: 30px;
            object-fit: cover;
            border-radius: 50%;
          }

          .teamScore1 {
            margin-left: 8px;
          }

          .teamScore2 {
            margin-right: 8px;
          }

          .teamIcon2 {
            width: 30px;
            height: 30px;
            object-fit: cover;
            border-radius: 50%;
          }

          .split {
            margin-left: 8px;
            margin-right: 8px;
          }
        }

      }

      .searchContainer {
        display: flex;
        align-items: center;
        margin-top: 16px;

        .createButton {
          margin-left: 16px;
        }
      }
    }


    .content {
      margin-top: 8px;
    }
  }
}
