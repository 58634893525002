@import "../../styles/variables";

.CreateQuoteScreen {

  .navigationBtn {
    display: none;

    @media (min-width: $tablet-width) {
      display: block;
    }
  }

  @media (min-width: $tablet-width) {
    @include centered;
    max-width: 800px;
    margin-top: 16px;
    padding-bottom: 16px;
  }

  .quoteCard {

    @media (min-width: $tablet-width) {
      @include centered;
      @include border-around-element;
      min-height: auto;
      max-width: 800px;
      margin-top: 16px;
      padding-bottom: 16px;
    }

    .container {
      width: 100%;

      @media (min-width: $mobile-width) {
        margin-top: 32px;
      }

      @media (min-width: $tablet-width) {
        @include centered;
        width: 100%;
        margin-top: 8px;
      }



      .actions {
        display: none;
        justify-content: space-between;
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 16px;

        @media (min-width: $desktop-width) {
          display: flex;
        }

        .navigationBtn {
          text-transform: capitalize;
        }

        .nextButton {
          @extend .navigationBtn;
        }

        .postButton {
          @extend .navigationBtn;
        }

        .saveAsDraft {
          text-transform: capitalize;
          margin-right: 16px;
        }

        .draftAndPostContainer {
          display: flex;
        }
      }

    }
  }
}
