@import "../../../styles/variables";

.TopicDiscussionCard {
  @include border-bottom-element;
  display: flex;
  align-items: center;
  padding: 16px;


  @media (min-width: $tablet-width) {
    margin-top: 16px;
    @include border-around-element;
  }

  &:hover {
    cursor: pointer;
  }

  .image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .textInfo {
    flex: 1;

    .titleContainer {
      display: flex;
      justify-content: space-between;

      .title {
        font-weight: bold;
      }
    }

  }

  .actionBox {

  }
}
