@import "../../../styles/variables";

.TopicCard {
  @include border-bottom-element;
  display: flex;
  align-items: center;
  padding: 16px;

  @media (min-width: $tablet-width) {
    @include border-around-element;
    margin-bottom: 16px;
  }

  &:hover {
    cursor: pointer;
  }

  .image {
    min-width: 50px;
    width: auto;
    height: 80px;
    object-fit: cover;
    margin-right: 16px;
  }

  .textInfo {
    flex: 1;

    .titleContainer {
      display: flex;
      justify-content: space-between;

      .title {
        font-weight: bold;
      }
    }

  }

  .actionBox {

  }
}
