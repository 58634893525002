@import "../../styles/variables";

.TopMediumScreen {

  .container {
    @include centered;
    width: 100%;

    @media (min-width: $tablet-width) {
      width: 500px;
    }

    .title {
      font-size: 24px;
      font-weight: 600;
      color: black;
      margin-top: 8px;
      margin-bottom: 8px;
      padding: 8px;

      @media (min-width: $tablet-width) {
        padding: 0px;
        font-size: 30px;
      }
    }

    .mobile {
      @media (min-width: $tablet-width) {
        display: none;
      }
    }

    .web {
      display: none;
      @media (min-width: $tablet-width) {
        display: grid;
      }
    }

    .mediumsContainer {
      margin-top: 16px;
      padding-bottom: 24px;
    }
  }
}
