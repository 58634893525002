@import "../../../styles/variables";

.MediumsGrid {

  .gridList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 100vw * 0.5;
    grid-column-gap: 0.5vw;
    grid-row-gap: 0.5vw;
    padding-bottom: 16px;
    min-height: 400px !important;
    height: auto !important;

    @media (min-width: $tablet-width) {
      grid-column-gap: 1vw;
      grid-row-gap: 1vw;
      margin-top: 16px;
      grid-auto-rows: 400px;
    }

    .spinningContainer {
      grid-column: span 3;
      text-align: center !important;
    }

    .skeletonItem {
      grid-column: span 1;
      width: 100%;
      height: 100%;
      bottom: 0;
    }
  }

  .emptyView {
    grid-column: span 3;
  }
}

.ant-skeleton-title {
  margin-top: 0px !important;
}
