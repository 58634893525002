@import "../../styles/variables";

.SearchScreen {

  .noResultSearch {
    font-size: 16px;

    .searchText {
      color: var(--primary-text-color);
    }
  }

  .container {
    @include centered;
    width: 100%;

    @media (min-width: $tablet-width) {
      width: $content-width;
    }

    .header {
      margin: 16px;

      @media (min-width: $tablet-width) {
        margin: 0px;
      }

      .searchContainer {
        margin-top: 8px;
        @media (min-width: $tablet-width) {
          padding: 16px 0px;
        }

        .searchInput {

        }
      }
    }

    .body {

      .resultContainer {
        margin-top: 16px;

        .books {

          .booksContainer {
            margin-bottom: 16px;

            @media (min-width: $tablet-width) {
              margin-top: 16px;
            }
          }


        }

        .users {

          .usersContainer {

            @media (min-width: $tablet-width) {
              margin-top: 16px;
              margin-bottom: 16px;
            }

          }

        }
      }
    }
  }
}
