@import "../../../styles/variables";

.ActivityTab {
  position: absolute;
  bottom: 0;
  width: 100%;
  max-width: $tablet-width;
  height: calc(100vh - 164px);
  display: flex;
  flex-direction: column;

  @media (min-width: $tablet-width) {
    position: relative;
    height: calc(100vh - 264px);
    margin-top: 8px;
    @include border-around-element;
  }

  .membersContainer {
    flex: 1 !important;
    overflow-y: auto !important;

    .groupInfo {
      display: flex;
      //justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 16px;

      .groupImage {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        border-style: solid;
        border-width: 1px;
        border-color: lightgrey;
      }

      .nameInput {
        flex: 1;
        margin-left: 16px;
        margin-right: 24px;
      }

      .saveButton {

      }
    }

    .membersTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 16px;

      .title {
        font-weight: bold;
        font-size: 15px;
      }
    }

    .membersList {
      //background: blue;

      .spinningContainer {
        text-align: center;
      }
    }
  }

  .divider {
    height: 1px;
    background: lightgrey;
    width: 100%;
  }

  .dangerButton {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .messagesContainer {
    flex: 1 !important;
    display: flex !important;
    flex-direction: column-reverse !important;
    overflow-y: auto !important;
    padding-bottom: 8px;

    .spinningContainer {
      text-align: center;
      padding-top: 16px;
    }

    .item {
      //background: orange;
      height: auto;
    }
  }

  .inputContainer {
    min-height: 50px;
    border-top-color: lightgrey;
    border-top-width: 1px;
    border-top-style: solid;
    height: auto;
    display: flex;
    align-items: center;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 8px;

    .sendingDataContainer {
      border-radius: 16px;
      border-color: lightgrey;
      border-width: 1px;
      border-style: solid;
      flex: 1;
      width: calc(100% - 200px);

      .attachmentContainer {
        padding-left: 12px;
        padding-right: 12px;
      }
    }

    .addButton {
      font-size: 22px;
      color: $blue;
      margin-right: 8px;
    }

    .textArea {
      //margin-left: 8px;
      font-size: 16px;
      border-radius: 16px;
    }

    .sendButton {
      margin-left: 8px;
      font-size: 22px;
      color: $blue;
    }
  }
}
