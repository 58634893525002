@import "../../../../../styles/variables";

.OneUser {
  flex: 1;
  padding: 16px 8px;

  .profileHeader {
    display: flex;
    align-items: center;

    .profileImage {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }

    .username {
      padding-left: 8px;
      font-weight: bold;
    }

  }

  .content {
    margin-top: 4px;
  }
}
