@import "../../styles/variables";

.ClubDiscussionCreateScreen {
  .container {
    @include centered;
    @include border-around-element;
    padding: 16px;

    @media (min-width: $tablet-width) {
      margin-top: 16px;
    }

    .header {
      display: flex;
      align-items: center;

      .goBack {
        margin-right: 16px;
        display: none;

        @media (min-width: $tablet-width) {
          display: block;
        }
      }

      .name {
        font-size: 18px;
      }
    }

    .form {
      margin-top: 24px;

    }
  }
}
