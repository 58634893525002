@import "../../../styles/variables";

.Snippet {
  margin-bottom: 16px;

  .container {
    @include border-around-element;
    padding: 12px;

    .header {
      text-transform: capitalize;
      color: gray;
    }

    .body {
      display: flex;
      padding-top: 8px;

      .title {
        flex: 1;
        padding-right: 8px;
      }

      .image {
        width: 64px;
        height: auto;
      }
    }

    .footer {
      display: flex;
      justify-content: flex-end;
      padding-top: 8px;
    }
  }
}
