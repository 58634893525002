@import "../../../styles/variables";

.PostFooter {
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-top: 4px;

  .actions {
    font-size: 26px;
    display: flex;
    align-items: center;

    .popoverContainer {
      font-weight: normal;
    }

    .likedIcon {
      color: $blue;
    }

    .defaultIcon {
      font-weight: normal;
    }

    .defaultLikeIcon {
      font-weight: normal;
      width: 30px;
      //color: var(--primary-text-color);
      filter: var(--main-filter);
    }

    .simpleLikeButton {
      font-weight: normal;
      font-size: 26px;
    }
  }

  .reactions {
    color: var(--primary-text-color);
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: 8px;
    $iconBottomPadding: 0px;
    $iconFontSize: 17px;

    .miniLikedIcon {
      @extend .likedIcon;
      font-size: $iconFontSize;
      padding-bottom: $iconBottomPadding;
      z-index: 2;
    }

    .miniHeartIcon {
      @extend .heartIcon;
      font-size: $iconFontSize;
      padding-bottom: $iconBottomPadding;
      z-index: 1;
    }

    .miniLolIcon {
      @extend .lolIcon;
      font-size: 15px;
    }

    .reactionCount {
      font-size: 14px;
      margin-left: 4px;
    }
  }

  .title {
    .titleUsername {
      color: var(--primary-text-color);
      font-weight: 600;
    }

    .titleText {
      padding-left: 12px;
    }
  }


}

.reactionsIcons {
  padding: 0px !important;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 26px !important;
}

.reactionIcon {
  &:hover {
    cursor: pointer;
    transform: scale(1.2);
  }
}

.popoverLikeIcon {
  @extend .reactionIcon;
  color: $blue !important;
}

.popoverHeartIcon {
  @extend .reactionIcon;
  color: red !important;
  margin-left: 16px;
}

.popoverLolIcon {
  @extend .reactionIcon;
  color: red !important;
  margin-left: 16px;
}

.likedIcon {
  color: $blue !important;
  width: 30px ;
}

.heartIcon {
  color: #ff0000 !important;
}

.reactionImage {
  width: 30px;
  height: 30px;
  object-fit: cover;

  &:hover {
    cursor: pointer;
  }
}

.miniReactionImage {
  width: 20px;
  object-fit: cover;
  border-radius: 50%;

  &:hover {
    cursor: pointer;
  }
}

.lolIcon {
  color: red !important;
  padding: 0px !important;
}

.lolIconHover {
  &:hover {
    cursor: pointer;
  }
}
