@import "../../styles/variables";

.MobileMenu {
  padding: 0px !important;

  .body {
    width: 100%;
    padding: 0px !important;

    .menuItem {
      padding: 16px;
      border-bottom-color: $backgroundGray;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      //color: var;
      font-weight: 500;
      font-size: 16px;
      display: flex;
      align-items: center;

      .icon {
        width: 18px;
      }

      .title {
        flex: 1;
        padding-left: 12px;
      }

    }

  }

  .bottomContent {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: var(--main-secondary-background-color);

    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: lightgrey;


    .themeItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 18px 12px;
    }

    .logout {
      color: $blue !important;
      padding: 18px 12px;
    }
  }
}
