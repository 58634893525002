@import "../../../../styles/variables";

.Timeline {
  @include border-around-element;
  padding: 16px;
  margin-top: 16px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 17px;
    }

    .viewAll {
      text-transform: capitalize;
    }
  }

  .content {
    margin-top: 16px;
    max-width: 100%;
    overflow: auto;

    .container {
      display: inline-flex;
      padding-bottom: 16px;

      .timelineCard {
        @include border-around-element;
        //padding: 8px;
        width: 280px;
        min-height: 200px;
        margin-right: 16px;
        display: flex;
        flex-direction: column;

        .header {
          @include border-bottom-element;
          padding: 8px;
        }

        .body {
          flex: 1;
          padding: 8px;
        }

        .footer {
          padding: 8px;
          @include border-top-element;
        }
      }

    }

  }
}
