@import "../../../styles/variables";

.TopStand {

  .container {

    .headerContainer {
      background: white;

      .header {
        @include border-element;
        padding: 16px;

        .titleContainer {
          display: flex;
          align-items: center;

          .title {
            flex: 1;
            font-size: 17px;
            font-weight: bold;
            text-align: left;
            padding-left: 8px;
          }
        }

        .searchContainer {
          margin-top: 12px;
        }
      }
    }


    .overviewCards {
      height: calc(100vh - 304px);
      overflow-y: auto;

      .officialStandOverviewCard {
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: lightgrey;

        &:hover {
          cursor: pointer;
        }

        .officialContent {

          .imageContainer {
            padding-top: 16px;
            text-align: center;

            .image {
              width: 24px;
              height: 24px;
              object-fit: cover;
            }
          }

          .title {
            font-weight: bold;
            text-align: center;
            margin-top: 4px;
          }

          .time {
            text-align: center;
            margin-top: 4px;
          }

          .players {
            display: flex;
            @include centered;
            align-items: center;
            justify-content: space-between;
            max-width: 340px;

            .player {
              display: flex;
              align-items: center;
              margin-top: 8px;


              .info {
                flex: 1;

                .in {
                  font-size: 11px;
                  color: green;
                }

                .out {
                  font-size: 11px;
                  color: red;
                }

                .name {
                  font-weight: bold;
                }

                .detailsContainer {
                  display: flex;

                  .detailsImage {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                  }

                  .detailsText {
                    padding-left: 8px;
                  }
                }
              }

              .image {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                margin-left: 16px;
              }
            }
          }
        }

        .footerContainer {
          display: flex;
          align-items: center;
          padding-left: 16px;
          padding-right: 16px;
          padding-bottom: 16px;

          .reactContainer {
            padding-left: 8px;
            padding-right: 16px;

            .reactionImage {
              width: 24px;
              height: 24px;
              object-fit: cover;
              margin-bottom: -2px;
            }
          }

          .footer {
            flex: 1;
            margin-top: 8px;
            display: flex;
            justify-content: space-between;

            .leftStats {
              display: flex;
              align-items: center;

              .reactionIcon1 {
                width: 18px;
                height: 18px;
                border-radius: 50%;
              }

              .reactionIcon2 {
                width: 18px;
                height: 18px;
                border-radius: 50%;
              }

              .reactionIcon3 {
                width: 18px;
                height: 18px;
                border-radius: 50%;
              }
            }

            .rightStats {
              display: flex;
              align-items: center;

              .shareIcon {
                width: 18px;
                height: 18px;
                border-radius: 50%;
              }

              .commentNumber {
                margin-right: 8px;
              }

              .commentIcon {
                width: 18px;
                height: 18px;
                border-radius: 50%;
              }
            }
          }
        }

      }

      .standOverviewCard {
        //border-left-width: 1px;
        //border-left-style: solid;
        //border-left-color: lightgrey;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: lightgrey;
        //border-right-width: 1px;
        //border-right-style: solid;
        //border-right-color: lightgrey;
        //background: blue;
        display: flex;
        padding: 16px;
        //margin-top: 8px;

        &:hover {
          cursor: pointer;
        }

        .imageContainer {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;

          .image {
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }

          .reactionImage {
            width: 24px;
            height: 24px;
            object-fit: cover;
            margin-bottom: 2px;
          }
        }

        .textInfo {
          flex: 1;
          padding-left: 8px;

          .titleContainer {
            display: flex;
            justify-content: space-between;

            > div {
              margin-bottom: 0 !important;
            }

            .title {
              font-weight: bold;
            }
          }

          .postContainer {

            .postText {
              margin-top: 4px;
            }

            .postImage {
              margin-top: 4px;
              width: 280px;
              height: auto;
              border-radius: 4px;
            }

            .footer {
              margin-top: 8px;
              display: flex;
              justify-content: space-between;

              .leftStats {
                display: flex;
                align-items: center;

                .reactionIcon1 {
                  width: 18px;
                  height: 18px;
                  border-radius: 50%;
                }

                .reactionIcon2 {
                  width: 18px;
                  height: 18px;
                  border-radius: 50%;
                }

                .reactionIcon3 {
                  width: 18px;
                  height: 18px;
                  border-radius: 50%;
                }
              }

              .rightStats {
                display: flex;
                align-items: center;

                .shareIcon {
                  width: 18px;
                  height: 18px;
                  border-radius: 50%;
                }

                .commentNumber {
                  margin-right: 8px;
                }

                .commentIcon {
                  width: 18px;
                  height: 18px;
                  border-radius: 50%;
                }
              }
            }
          }
        }

      }

    }

  }
}
