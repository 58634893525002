@import "../../../styles/variables";

.DraggableList {

  .addNewMediumContainer {
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    justify-content: space-between;

    @media (min-width: $tablet-width) {
      padding-left: 0;
      padding-right: 0;
    }

    .addNewMediumButton {
      height: 40px;
    }

    .saveButton {
      height: 40px;
      background: $green;
      border-color: $green;
    }

  }

}
