@import "../../../styles/variables";

.PostHeader {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 12px;


  .profileImage {
    height: 34px;
    width: 34px;
    border-radius: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    border-color: lightgrey;
    border-style: solid;
    border-width: 1px;

    &:hover {
      cursor: pointer;
    }
  }

  .userInfo {
    padding-left: 12px;
    flex: 1;

    .username {
      &:hover {
        cursor: pointer;
      }
    }

    .displayName {
      color: var(--primary-text-color);
    }

    .timestamp {
      font-size: 10px;
    }
  }

  .more {

  }
}
