@import "../../styles/variables";

.PostScreen {
  max-width: 500px;
  @include centered;

  .content {


  }
}
