@import "../../styles/variables";

.WishListScreen {
  @include centered;
  max-width: $content-width;
  padding-top: 8px;

  .container {

    width: 100%;

    .tabs {
      .ant-tabs-nav {
        margin-bottom: 0px !important;
      }
    }

    .header {
      height: auto;
      display: flex;
      padding-top: 16px;

      @media (min-width: $tablet-width) {
        height: 300px;
        align-items: center;
        margin-top: 0;
      }

      .profileInfo {
        flex: 1;
        margin-right: 16px;

        .profileInfoFooter {
          color: rgba(0, 0, 0, 1);
          display: none;

          @media (min-width: $tablet-width) {
            display: block;
          }
        }
      }
    }

  }

}

.tabs {
  margin-bottom: 0px !important;
}

.ant-tabs-nav {
  margin-bottom: 0px !important;
}
