@import "../../../../styles/variables";

.BackgroundText {
  background: var(--main-background-color);
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
  width: 100%;
  height: 100%;
  padding: 8px;
  color: var(--primary-text-color);
  position: relative;
  overflow: hidden;

  .textContainer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.05;
    font-size: 22px;
    font-weight: 700;
  }

  .container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 8px;

    .title {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      align-content: center;
    }

    .source {

    }
  }
}
