@import "../../../styles/variables";

.AuthModal {

  .loginButton {
    font-size: 14px;
  }

  .signupButton {
    font-size: 14px;
  }

  .modalTitle {
    display: flex;
    align-items: center;
    padding-left: 16px;
    padding-bottom: 8px;
    padding-top: 8px;
    text-transform: capitalize;
  }

  .modalBody {
    padding: 16px;
    text-align: center;
    align-items: center;

    .privateBeta {
      padding-bottom: 16px;
    }

    .socialButton {
      width: 200px;
    }

    .googleButton {
      @extend .socialButton;
    }

    .googleIcon {
      color: $red;
    }

    .twitterIcon {
      color: $blue;
    }

    .facebookIcon {
      color: #3b5998;
    }

    .mailIcon {
      color: gray;
    }

    .twitterButton {
      @extend .socialButton;
    }

    .facebookButton {
      @extend .socialButton;
    }

    .mailButton {
      @extend .socialButton;
    }
  }

}
