@import "../../../styles/variables";

.RatingThumbnail {
  width: 100vw;
  height: 100vw;
  background: white;
  position: relative;
  color: black;

  @media (min-width: 400px) {
    margin-top: 16px;
    width: 400px;
    height: 400px;
  }

  .container {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;

    .leftContainer {

      .ratingProgress {

        .ratingValue {
          font-size: 54px;
          color: black;
          font-weight: 500;
        }

        .outOf {
          color: gray;
        }
      }
    }

    .rightContainer {
      flex: 1;

      .imageContainer {
        text-align: center;

        & > span {
          height: 260px;
          width: 162px;

        }

        .image {
          height: auto;
          width: 162px;
          border-color: lightgrey;
          border-width: 1px;
          border-style: solid;

        }
      }
    }
  }

  .layer2 {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    text-align: center;
    padding-bottom: 16px;
    color: black;

    .swipe {
      margin-right: 4px;
    }
  }

  .layer3 {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    text-align: center;
    padding-top: 24px;
    font-weight: 600;
    font-size: 17px;
    color: black;
  }
}