@import "../../styles/variables";

.CreateList {
  display: flex;
  flex-direction: column;
  align-items: center;

  .thumbnail {
    //position: absolute;
    //top: -3000px;
    background: #454545;
    width: 400px;
    height: 400px;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    .text {
      //height: 100%;
      text-align: center;
      font-size: 48px;
      font-weight: 600;
      margin: auto;
      color: white;
    }
  }

  .container {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-width: $tablet-width;
    height: calc(100vh - 65px);
    display: flex;
    flex-direction: column;

    @media (min-width: $tablet-width) {
      position: relative;
      height: calc(100vh - 96px);
      margin-top: 8px;
      @include border-around-element;
    }

    .listContainer {
      flex: 1 !important;
      overflow-y: auto;

      .inputContainer {
        //display: flex;
        //justify-content: space-around;
      }
    }

    .membersContainer {
      flex: 1 !important;
      //display: flex !important;
      //flex-direction: column !important;
      overflow-y: auto !important;

      .groupInfo {
        display: flex;
        //justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 16px;

        .groupImage {
          width: 40px;
          height: 40px;
          border-radius: 20px;
          border-style: solid;
          border-width: 1px;
          border-color: lightgrey;
        }

        .nameInput {
          flex: 1;
          margin-left: 16px;
          margin-right: 24px;
        }

        .saveButton {

        }
      }

      .membersTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 16px;

        .title {
          font-weight: bold;
          font-size: 15px;
        }
      }

      .membersList {
        //background: blue;

        .spinningContainer {
          text-align: center;
        }
      }
    }

    .divider {
      height: 1px;
      background: lightgrey;
      width: 100%;
    }

    .dangerButton {
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
    }

    .messagesContainer {
      flex: 1 !important;
      display: flex !important;
      flex-direction: column-reverse !important;
      overflow-y: auto !important;
      padding-bottom: 8px;

      .spinningContainer {
        text-align: center;
        padding-top: 16px;
      }

      .item {
        //background: orange;
        height: auto;
      }
    }

    .inputContainer {
      min-height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include border-top-element;

      .previousButton {
        margin-left: 8px;
      }

      .nextButton {
        margin-right: 8px;
      }
    }
  }
}