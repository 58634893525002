
.AddThoughts {
  padding: 16px;

  .thoughtInput {
    margin-top: 16px;
    height: 80px;
    padding: 6px 11px;
    font-size: 16px;
  }
}
