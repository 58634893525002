@import "../../styles/variables";

.ProfileScreen {

  .spinningContainer {
    text-align: center;
  }

  .followInfoContainer {
    margin-top: 8px;
    display: flex !important;
    //flex-direction: row;

    .followersCount {
      margin-left: 4px;
    }
  }

  .container {
    @include centered;
    width: 100%;

    @media (min-width: $tablet-width) {
      width: $content-width;
    }

    .header {
      height: auto;
      display: flex;
      padding-top: 16px;

      @media (min-width: $tablet-width) {
        height: 220px;
        align-items: center;
        margin-top: 0;
      }

      .profileImageSkeleton {
        width: 70px;
        height: 70px;

        @media (min-width: $tablet-width) {
          width: 150px;
          height: 150px;
        }
      }

      .profileImage {
        width: 70px;
        height: 70px;
        max-width: 70px;
        overflow: hidden;
        border-radius: 35px;
        margin-left: 16px;
        margin-right: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 100px;
        object-fit: cover;
        border-color: lightgray;
        border-width: 1px;
        border-style: solid;
        background: var(--main-background-color);

        @media (min-width: $tablet-width) {
          width: 150px;
          height: 150px;
          max-width: 150px;
          overflow: hidden;
          border-radius: 75px;
          margin-left: 50px;
          margin-right: 50px;
          border-color: lightgray;
          border-width: 1px;
          border-style: solid;
          background: var(--main-background-color);
        }
      }

      .profileInfo {
        //flex: 3;
        flex: 1;
        margin-right: 16px;

        //@media (min-width: $tablet-width) {
        //  margin-right: 16px;
        //}

        .profileInfoHeader {
          //background: green;

          @media (min-width: $tablet-width) {
            display: flex;
            align-items: center;
          }

          .username {
            font-size: 18px;
            color: var(--primary-text-color);

            .setting {
              color: gray;
              padding-left: 8px;

              @media (min-width: $tablet-width) {
                display: none;
              }
            }
          }

          .follow {
            margin-top: 8px;

            @media (min-width: $tablet-width) {
              margin-left: 16px;
              margin-top: 0px;
            }
          }

          .more {
            margin-left: 16px;
          }
        }

        .profileInfoBody {
          padding: 24px 0px 24px 0px;
          display: none;
          font-size: 16px;

          @media (min-width: $tablet-width) {
            display: flex;
          }

          .followersCount {
            cursor: pointer;
            text-align: center;
            @media (min-width: $tablet-width) {
              text-align: left;
            }
          }

          .followingCount {
            margin-left: 16px;
            cursor: pointer;
            text-align: center;
            @media (min-width: $tablet-width) {
              text-align: left;
            }
          }
        }

        .profileInfoFooter {
          color: var(--primary-text-color);
          display: none;

          @media (min-width: $tablet-width) {
            display: block;
          }

          .displayName {
            font-size: 16px;
          }

          .bio {

          }
        }
      }
    }

    .mobileInfo {
      margin: 16px 16px 8px 16px;

      @media (min-width: $tablet-width) {
        display: none;
      }

    }

    .profileMobileInfoStats {
      display: flex;
      border-top-style: solid;
      border-top-width: 1px;
      border-top-color: lightgrey;
      border-bottom-style: solid;
      border-bottom-width: 1px;
      border-bottom-color: lightgrey;
      padding-top: 8px;
      padding-bottom: 8px;
      text-align: center;

      @media (min-width: $tablet-width) {
        display: none;
      }

      .postsCount {
        flex: 1;
      }

      .followersCount {
        flex: 1;
      }

      .followingCount {
        flex: 1;
      }
    }

    .privateBanner {
      @include border-around-element;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 32px;

      .privateTitle {
        font-weight: 700;
        color: black;
      }

      .privateSubtitle {
        margin-top: 16px;
      }
    }

    .tabMessage {
      text-align: center;
      padding-top: 8px;
      padding-bottom: 8px;
    }

    .booksList {

      @media (min-width: $tablet-width) {
        margin-top: 16px;
      }
    }

    .postsList {

      @media (min-width: $tablet-width) {
        margin-top: 16px;
      }
    }

  }
}
