@import "../../../styles/variables";

.PreviewSummary {

  p {
    margin-top: 0;
    margin-bottom: 0px;
  }

  .header {
    padding: 16px;

    .headerContainer {
      height: 100%;
      display: flex;
      flex-direction: column;

      .title {
        flex: 1;
        font-size: 28px;
        font-weight: 600;
        color: black;
      }

      .summaryLabel {
        background: $summary-green;;
        color: black;
        padding-left: 2px;
        padding-right: 2px;
        margin-right: 4px;
        font-style: italic;
      }

      .headerInfo {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;

        .medium {
          display: flex;
          padding-top: 8px;

          .mediumSubtitle {
            font-size: 16px;
            font-weight: 400;
            color: #4c4c4c;
          }

          .mediumImage {
            height: 70px;
            width: 50px;
          }

          .mediumInfo {
            padding-left: 8px;

            .mediumTitle {
              color: black;
            }

            .mediumAuthor {
              color: #3c3c3c;
            }
          }

        }

        .user {
          display: flex;
          padding-top: 8px;
          padding-bottom: 8px;
          align-items: center;

          .userImage {
            width: 40px;
            height: 40px;
            border-radius: 20px;
            object-fit: cover;
            border-width: 1px;
            border-color: lightgrey;
            border-style: solid;
          }

          .userInfo {
            padding-left: 8px;
            flex: 1;
            color: black;

            .username {
              font-weight: 400;
            }

            .timestamp {
              color: gray;
              font-size: 10px;
            }
          }

          .mediumImage {
            width: 40px;
            height: 50px;
            object-fit: contain;
          }
        }

        .follow {
          margin-left: 16px;
        }
      }

    }
  }

  .body {
    padding: 16px;
    color: black;
    font-size: 16px;

    .addPost {
      width: 100%;
      margin-top: 24px;
    }

    .addDraft {
      width: 100%;
      margin-top: 24px;
    }
  }
}
