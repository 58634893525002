
.SettingsModal {

  .title {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 12px;
    padding-right: 12px;
  }

  .modalBody {
    padding: 0px !important;
    //background: green;
    //margin-top: 8px;
    //margin-bottom: 8px;
    //padding-top: 8px !important;
    padding-bottom: 16px !important;

    .logout {
      color: black;
      width: 100%;
      border-bottom-color: lightgrey;
      border-bottom-style: solid;
      border-bottom-width: 1px;
    }

    .cancel {
      color: black;
      width: 100%;
      border-bottom-color: lightgrey;
      border-bottom-style: solid;
      border-bottom-width: 1px;
    }
  }
}
