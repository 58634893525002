@import "../../../styles/variables";

.TopCard {
  //border: 1px solid black;
  padding: 8px;
  margin-bottom: .5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  @include border-bottom-element;

  @media (min-width: $tablet-width) {
    @include border-around-element;
  }

  .iconContainer {

  }

  .leftContainer {

    .rankNum {
      font-size: 40px;
      font-weight: 800;
      padding-left: 8px;
      text-align: center;
    }
  }

  .centerContainer {
    flex: 1;
    display: flex;
    margin-left: 16px;

    .image {
      height: 160px;

      @media (min-width: $tablet-width) {
        height: 160px;
      }
    }

    .mediumInfo {
      display: flex;
      flex-direction: column;
      margin-left: 16px;

      .mediumTitle {
        flex: 1;
        font-size: 16px;
        color: var(--primary-text-color);
      }

      .mediumActions {
        //display: flex;

        @media (min-width: $tablet-width) {
          display: flex;
        }

        .shelfButton {
          //margin-bottom: 4px;

          @media (min-width: $tablet-width) {
            //margin-right: 8px;
            //margin-bottom: 0px;
          }
        }

        .activeShelfButton {
          background: $green;
          border-color: $green;
        }

        .wishListButton {

        }

        .activeWishListButton {

        }

        .removeButton {
          background: $red;
          border-color: $red;
        }
      }
    }
  }

}
