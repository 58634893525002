@import "../../styles/variables";

.TabIcon {
  width: calc(100vw * 0.19598);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: $tablet-width) {
    width: calc(#{$content-width} * 0.2);
  }

  .icon {
    width: 20px;
    filter: var(--main-filter);
  }

  .active {
    filter: invert(73%) sepia(65%) saturate(5192%) hue-rotate(178deg) brightness(93%) contrast(84%);
  }

  .count {
    display: none;
    margin-left: 8px;
    text-transform: capitalize;

    //@media (min-width: $tablet-width) {
    //  display: block;
    //}
  }
}
