@import "../../../styles/variables";

.PostDivider {
  height: 1px;
  width: 100%;

  .divider {
    background: var(--main-home-divider-color);
    height: 1px;
  }
}
