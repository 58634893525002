@import "../../styles/variables";

.NewGroupUsersScreen {

  .navContainer {
    width: 100%;
    background: white;
    padding: 16px;
    display: flex;
    justify-content: center;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: lightgrey;

    .navBody {
      display: flex;
      justify-content: space-between;
      width: 800px;
    }
  }

  .headerContainer {
    max-width: $content-width;
    margin-left: auto;
    margin-right: auto;
    position: sticky;
    top: 65px;

    .headerWhiteboard {
      background: var(--main-background-color);

      @media (min-width: $tablet-width) {
        padding-top: 8px;
      }

      .messageHeader {
        @include border-around-element;
        padding: 12px 16px;

        @media (min-width: $tablet-width) {
          margin-top: 12px;
        }

        .topHeader {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .titleHeader {
            font-size: 18px;
            font-weight: bold;
            color: black;
          }
        }


      }

      .selectedUsers {
        @include border-around-element;
        padding: 8px 16px;
        margin-top: 8px;
        max-width: 100%;
        overflow: auto;

        .selectedUsersContainer {
          display: inline-flex;

          .selectedUser {
            margin-right: 16px;
            width: 70px;
            position: relative;
            text-align: center;

            .image {
              width: 56px;
              height: 56px;
              border-radius: 28px;
            }

            .username {
              margin-top: 4px;
              font-size: 10px;
            }
          }

        }

      }

      .search {
        @include border-around-element;
        padding: 16px;
        margin-top: 8px;
        width: 100%;

        .topHeader {
          display: flex;
          align-items: center;

          .titleHeader {
            flex: 1;
            font-size: 18px;
            font-weight: bold;
            color: black;
            margin-left: 8px;
          }

        }

        .searchContainer {
          margin-top: 8px;

          //.nextContainer {
          //  display: flex;
          //  justify-content: flex-end;
          //
          //  .nextButton {
          //    margin-top: 12px;
          //  }
          //}

        }
      }
    }

  }

  .container {
    max-width: $content-width;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 16px;
  }
}
