@import "../../../../styles/variables";

.borderAround {
  @include border-bottom-element;

  @media (min-width: $tablet-width) {
    border-width: 1px;
    border-style: solid;
    border-color: lightgrey;
    border-radius: 4px;
    margin-top: 16px;
  }
}

.borderTop {
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: lightgrey;
}

.RecentActivityCard {
  padding: 16px;

  &:hover {
    cursor: pointer;
  }

  .clubContainer {
    .name {
      font-weight: bold;
    }
  }

  .recentPostContainer {
    margin-top: 8px;
    display: flex;

    .notAvailable {
      @include border-around-element;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 16px;
    }

    &:hover {
      //cursor: pointer;
    }

    .imageContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .image {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }

      .reactionImage {
        width: 24px;
        height: 24px;
        object-fit: cover;
        margin-bottom: 1px;
        margin-top: 12px;

        &:hover {
          cursor: pointer;
        }
      }

      .defaultReactionImage {
        @extend .reactionImage;
        filter: var(--main-filter);
      }
    }

    .textInfo {
      flex: 1;
      padding-left: 8px;
      width: calc(100% - 200px);

      .titleContainer {
        display: flex;
        justify-content: space-between;

        > div {
          margin-bottom: 0 !important;
        }

        .title {
          font-weight: bold;
        }
      }

      .postContainer {

        .postText {
          margin-top: 4px;
          white-space: pre-line;
        }

        .postImage {
          margin-top: 4px;
          width: 280px;
          height: auto;
          border-radius: 4px;
        }

        .footer {
          margin-top: 8px;
          display: flex;
          justify-content: space-between;

          .leftStats {
            display: flex;
            align-items: center;

            .reactionIcon1 {
              width: 18px;
              height: 18px;
              border-radius: 50%;
            }

            .reactionIcon2 {
              width: 18px;
              height: 18px;
              border-radius: 50%;
            }

            .reactionIcon3 {
              width: 21px;
              height: 21px;
              border-radius: 50%;
            }

            .reactionNumber {
              margin-left: 4px;
            }
          }

          .rightStats {
            display: none;
            align-items: center;

            .shareIcon {
              width: 18px;
              height: 18px;
              border-radius: 50%;
            }

            .commentNumber {
              margin-right: 8px;
            }

            .commentIcon {
              width: 18px;
              height: 18px;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
}

.popoverItem {
  //background: blue !important;
}

.reactionsIcons {

  .reactionIcon {
    width: 30px;
    height: 30px;
    margin-right: 8px;
    margin-top: 6px;
    margin-bottom: 6px;

    &:hover {
      cursor: pointer;
      margin-top: 0px;
      margin-bottom: 12px;
    }
  }
}