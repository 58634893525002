@import "../../styles/variables";

.Medium {

  .mediumContainer {
    display: flex;

    .image {
      height: 200px;
      width: auto;
    }

    .podcastImage {
      height: 40vw;

      @media (min-width: $tablet-width) {
        height: 200px;
        width: auto;
      }
    }

    .mediumInfo {
      padding-left: 8px;
      overflow-x: hidden;

      .title {
        font-size: 16px;
        font-weight: 700;
        color: var(--primary-text-color);
      }

      .author {
        font-weight: 500;
        //color: var(--secondary-text-color);
      }

      .description {
        margin-top: 4px;
        //color: var(--secondary-text-color);

        .clampText {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .clampText > p {
          padding: 0;
          margin: 0;
        }

        .outer {
          display: grid;
          grid-template: 1fr / 1fr;
          //place-items: center;

          .below {
            grid-column: 1 / 1;
            grid-row: 1 / 1;
            z-index: 2;
            background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.7987570028011204) 40%, rgba(255,255,255,0.6530987394957983) 81%, rgba(255,255,255,0) 100%);
            height: 100%;
            align-self: flex-end;
            display: flex;
            flex-direction: column;
            //justify-content: center;
            align-items: center;

            .belowTitle {
              color: red;
              font-weight: 600;
              margin-top: 32px;
            }

            .belowButton {
              //background: blue;
            }
          }

          .top {
            grid-column: 1 / 1;
            grid-row: 1 / 1;
            z-index: 1;
          }
        }
      }
    }
  }

  .actions {
    margin-top: 16px;

    .actionButton {
      margin-right: 8px;

      @media (min-width: $tablet-width) {
        margin-right: 16px;
      }
    }

    .wishListButton {
      @extend .actionButton;
      background: $green;
      border-color: $green;
      cursor: default;
    }
  }
}
