@import "../../../../../styles/variables";

.Footer {
  padding: 8px;
  @include border-top-element;

  .reactionStats {
    display: flex;
    justify-content: space-between;

    .leftStats {
      display: flex;
      align-items: center;

      .reactionIcon1 {
        width: 18px;
        height: 18px;
        border-radius: 50%;
      }

      .reactionIcon2 {
        width: 18px;
        height: 18px;
        border-radius: 50%;
      }

      .reactionIcon3 {
        width: 18px;
        height: 18px;
        border-radius: 50%;
      }
    }

    .rightStats {
      display: flex;
      align-items: center;

      .shareIcon {
        width: 18px;
        height: 18px;
        border-radius: 50%;
      }

      .commentNumber {
        margin-right: 8px;
      }

      .commentIcon {
        width: 18px;
        height: 18px;
        border-radius: 50%;
      }
    }
  }

  .actions {
    margin-top: 4px;
    display: flex;
    border-top-color: lightgrey;
    border-top-width: 1px;
    border-top-style: solid;
    padding-top: 2px;

    .reactionButton {
      text-align: center;
      flex: 1;

      > img {
        width: 20px;
        height: 20px;
      }
    }

    .commentButton {
      text-align: center;
      flex: 1;
      //background: blue;

      > img {
        width: 20px;
        height: 20px;
      }

    }

    .shareButton {
      text-align: center;
      flex: 1;
      //background: green;

      > img {
        width: 20px;
        height: 20px;
      }
    }
  }
}
