@import "../../styles/variables";

.Logo {
  font-size: 22px;
  font-weight: 500;
  color: var(--primary-text-color);

  &:hover {
    cursor: pointer;
  }

  .o1 {
    //color: $logoBlue;
  }

  .o2 {
    //color: $logoRed;
  }

  .o3 {
    //color: $logoGreen;
  }

  .e {
    //color: $logoYellow;
  }
}