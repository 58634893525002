@import "../../styles/variables";

.MediumScreen {
  @include centered;
  max-width: 800px;
  padding-bottom: 16px;

  .header {
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 16px;

    @media (min-width: $tablet-width) {
      padding-left: 0px;
      padding-right: 0px;
      margin-top: 16px;
    }
  }

  .ratingContainer {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
    width: auto;

    @media (min-width: $tablet-width) {
      width: 280px;
    }
  }

  .container {
    @include centered;
    max-width: $content-width;

    @media (min-width: $tablet-width) {
      //margin-top: 16px;
      //border-radius: 4px;
      //border-style: solid;
      //border-width: 1px;
      //border-color: lightgrey;
    }

    .medium {
      padding: 16px;
      display: flex;

      .image {
        height: 100px;
        //width: 70px;
        border-radius: 4px;
      }

      .mediumInfo {
        padding-left: 8px;

        .title {
          font-size: 16px;
          font-weight: 700;
          color: var(--primary-text-color);
        }

        .author {

        }
      }
    }

    .content {
      margin-top: 8px;

      @media (min-width: $tablet-width) {
        margin-top: 24px;
      }

      .postsList {

        @media (min-width: $tablet-width) {
          margin-top: 16px;
        }

        .item {
          position: relative;
          margin-bottom: 0 !important;

          @media (min-width: $tablet-width) {
            margin-bottom: 16px !important;
            margin: 16px;
          }

          &:hover {

            .postBackground {
              cursor: pointer;
              position: absolute;
              bottom: 0;
              width: 100%;
              height: 100%;
              background: var(--main-background-color);
              opacity: 0.4;
            }

            .postReactions {
              opacity: 1;
              cursor: pointer;
            }

            .mediumBackground {
              cursor: pointer;
              position: absolute;
              bottom: 0;
              width: 100%;
              height: 100%;
              background: var(--main-background-color);
              opacity: 0.4;
            }

            .mediumReactions {
              opacity: 1;
              cursor: pointer;
            }
          }

          .post {
            //height: 190px;
            width: 100%;
            overflow: hidden;
            object-fit: cover;

            @media (min-width: $tablet-width) {
              //height: 360px;
            }
          }

          .medium {
            height: 190px;
            width: 100%;
            overflow: hidden;
            object-fit: cover;

            @media (min-width: $tablet-width) {
              height: 360px;
            }
          }

          .postBackground {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            color: var(--primary-text-color);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-weight: 800;
          }

          .postReactions {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            color: var(--primary-text-color);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-weight: 800;
          }

          .mediumBackground {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            color: var(--primary-text-color);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-weight: 800;
          }

          .mediumReactions {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            color: var(--primary-text-color);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-weight: 800;
          }
        }
      }

      .emptyEpisodes {
        text-align: center;
        padding-top: 24px;

        .emptyEpisodeTitle {
          //color: var(--ba);
          font-size: 17px;
        }

        .loadEpisode {
          margin-top: 16px;
        }
      }
    }
  }

  .skeletonContainer {
    padding-top: 16px;

    .mediumSkeletonContainer {
      display: flex;

      .mediumSkeleton {
        width: 160px;
        height: 260px !important;
      }

      .mediumTitleSkeleton {
        padding-left: 16px;
      }
    }

    .ratingSkeleton {
      margin-top: 16px;
      width: 60%;
      height: 100px !important;
    }

    .tabsSkeleton {
      margin-top: 16px;
      width: 80%;
      height: 60px !important;
    }
  }

}
