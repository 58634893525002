@import "../../../styles/variables";

.YesNoCard {
  width: 100%;
  //border-style: solid;
  //border-width: 1px;
  //border-color: lightgrey;
  //border-radius: 2px;
  text-align: center;
  padding: 16px 8px;

  .title {
    //color: black;
    font-size: 18px;
    font-weight: bold;
  }

  .image {
    border-radius: 4px;
    width: 80%;
    height: auto;
    margin-top: 16px;
  }

  .actions {
    margin-top: 24px;

    .optionButton {
      margin-top: 16px;
    }

    .noButton {
      @extend .optionButton;
    }

    .yesButton {
      @extend .optionButton;
    }

    .noButton {
      @extend .optionButton;
    }

    .answerNo {
      background: red;
      border-color: red;
      color: white;
    }

    .answerYes {
      //margin-top: 24px;
      background: $green;
      border-color: $green;
      color: white;
    }
  }
}
