@import "../../../styles/variables";

.Templates {
  //width: calc(100% - 100px);//max(300px, 200px);
  //max-width: 100vw;
  width: calc(100vw - 32px);
  overflow: auto;

  @media (min-width: $tablet-width) {
    width: initial;
  }

  .templateContainer {
    //width: 100%;
    display: inline-flex;

    @media (min-width: $tablet-width) {

    }
  }
}

@function getMaxVal($val1, $val2) {
  @if $val1 > $val2 {
    @return $val1;
  }
  @return $val2;
}
