@import "../../styles/variables";

.MediumEpisodesScreen {
  @include centered;
  max-width: 800px;

  .header {
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 16px;

    @media (min-width: $tablet-width) {
      padding-left: 0px;
      padding-right: 0px;
      margin-top: 16px;
    }

    .mediumContainer {
      display: flex;

      .image {
        height: 40vw;

        @media (min-width: $tablet-width) {
          height: 200px;
          width: auto;
        }
      }

      .mediumInfo {
        padding-left: 8px;
        overflow-x: hidden;

        .title {
          font-size: 16px;
          font-weight: 700;
          color: var(--primary-text-color);
        }

        .author {
          font-weight: 500;
        }

        .description {
          margin-top: 4px;

          .clampText {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

          .clampText > p {
            padding: 0;
            margin: 0;
          }

          .outer {
            display: grid;
            grid-template: 1fr / 1fr;
            //place-items: center;

            .below {
              grid-column: 1 / 1;
              grid-row: 1 / 1;
              z-index: 2;
              background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.7987570028011204) 40%, rgba(255,255,255,0.6530987394957983) 81%, rgba(255,255,255,0) 100%);
              height: 100%;
              align-self: flex-end;
              display: flex;
              flex-direction: column;
              //justify-content: center;
              align-items: center;

              .belowTitle {
                color: red;
                font-weight: 600;
                margin-top: 32px;
              }

              .belowButton {
                //background: blue;
              }
            }

            .top {
              grid-column: 1 / 1;
              grid-row: 1 / 1;
              z-index: 1;
            }
          }
        }
      }
    }
  }

  .ratingContainer {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
    width: auto;

    @media (min-width: $tablet-width) {
      width: 280px;
    }
  }

  .container {
    @include centered;
    max-width: $content-width;

    @media (min-width: $tablet-width) {
      //margin-top: 16px;
      //border-radius: 4px;
      //border-style: solid;
      //border-width: 1px;
      //border-color: lightgrey;
    }

    .medium {
      padding: 16px;
      display: flex;

      .image {
        height: 100px;
        //width: 70px;
        border-radius: 4px;
      }

      .mediumInfo {
        padding-left: 8px;

        .title {
          font-size: 16px;
          font-weight: 700;
          color: black;
        }

        .author {

        }
      }
    }

    .content {
      margin-top: 8px;

      @media (min-width: $tablet-width) {
        margin-top: 24px;
      }

      .postsList {

        @media (min-width: $tablet-width) {
          margin-top: 16px;
        }

        .item {
          position: relative;
          margin-bottom: 0 !important;

          @media (min-width: $tablet-width) {
            margin-bottom: 16px !important;
            margin: 16px;
          }

          &:hover {

            .postBackground {
              cursor: pointer;
              position: absolute;
              bottom: 0;
              width: 100%;
              height: 100%;
              background: black;
              opacity: 0.4;
            }

            .postReactions {
              opacity: 1;
              cursor: pointer;
            }

            .mediumBackground {
              cursor: pointer;
              position: absolute;
              bottom: 0;
              width: 100%;
              height: 100%;
              background: black;
              opacity: 0.4;
            }

            .mediumReactions {
              opacity: 1;
              cursor: pointer;
            }
          }

          .post {
            //height: 190px;
            width: 100%;
            overflow: hidden;
            object-fit: cover;

            @media (min-width: $tablet-width) {
              //height: 360px;
            }
          }

          .medium {
            height: 190px;
            width: 100%;
            overflow: hidden;
            object-fit: cover;

            @media (min-width: $tablet-width) {
              height: 360px;
            }
          }

          .postBackground {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-weight: 800;
          }

          .postReactions {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-weight: 800;
          }

          .mediumBackground {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-weight: 800;
          }

          .mediumReactions {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-weight: 800;
          }
        }
      }
    }
  }

  .actions {
    margin-top: 16px;

    .actionButton {
      margin-right: 8px;

      @media (min-width: $tablet-width) {
        margin-right: 16px;
      }
    }

    .wishListButton {
      @extend .actionButton;
      background: $green;
      border-color: $green;
      cursor: default;
    }
  }

  .sourceContainer {
    @include border-around-element;
    margin-top: 16px;
    padding: 16px;
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    .sourceImage {
      width: 50px;
      height: 50px;
    }

    .sourceTitle {
      padding-left: 12px;
      flex: 1;
    }
  }
}
