
.previewThumbnailContainer {
  text-align: center;

  .previewImage {
    width: 90vw;
    height: 90vw;
    margin-top: 16px;
    margin-bottom: 24px;

    @media (min-width: 400px) {
      width: 400px;
      height: 400px;
      margin-top: 0px;
    }
  }

  .downloadButton {
    margin-top: 32px;
    width: 100%;
    max-width: 400px;
    margin-bottom: 16px;
  }
}

.previewThumbnailModal {
  width: 100vw !important;
}