
.QuizQuestions {
  padding: 8px;

  .addOption {
    width: 100%;
  }

  .addQuestion {
    width: 100%;
    margin-top: 16px;
  }

  .answerItem {
    margin-top: 16px;
  }
}