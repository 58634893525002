@import "../../../../../styles/variables";

.CommentaryCard {
  @include border-around-element;
  width: 280px;
  min-height: 200px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;

  .commentary {
    flex: 1;
    font-size: 16px;
    padding: 16px;
  }
}
