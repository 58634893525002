
.GoalUser {
  display: flex;
  padding: 12px;

  .info {
    flex: 1;

    .name {
      font-weight: bold;
    }
    .detailsContainer {

      .detailsImage {
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }
    }
  }

  .image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}
