@import "../../../styles/variables";


.ClubHomeTab {
  max-width: calc(#{$content-width} - 40px);
  margin-left: auto;
  margin-right: auto;

  .contentContainer {
    padding-top: 16px;
    width: 100vw;

    @media (min-width: $tablet-width) {
      width: 100%;
    }

    .title {
      font-size: 16px;
      color: var(--primary-text-color);
      font-weight: 600;
      margin-bottom: 8px;
      padding-left: 16px;

      @media (min-width: $tablet-width) {
        padding-left: 0px;
      }
    }

    .storyCardsContainer {
      width: 100%;
      overflow: auto;
      padding-left: 16px;

      @media (min-width: $tablet-width) {
        padding-left: 0px;
      }

      .storyCards {
        display: inline-flex;
        padding-bottom: 12px;
      }

    }
  }

}