@import "../../../styles/variables";

.YouScreen {
  padding-top: 16px;

  .container {
    @include border-around-element;
    padding: 16px;

    .headerTitle {
      text-align: center;
      font-size: 16px;
    }

    .statsContainer {
      display: flex;
      justify-content: center;
      margin-top: 16px;

      .leftContainer {
        text-align: center;

        .image {
          width: 100px;
          height: 100px;
          border-radius: 50%;
        }

        .username {
          margin-top: 8px;
        }
      }

      .rightContainer {

        .statItem {
          width: 240px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
}
