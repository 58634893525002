@import "../../../styles/variables";

.ListStories {
  padding-top: 16px;
  .preview {
    width: 400px;
    height: 400px;
  }

  .previewItem {
    @include border-around-element;
    padding: 16px;
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px;
    text-align: center;
    overflow: hidden;

    .image {
      width: 160px;
      height: 220px;
      object-fit: contain;
    }

    .title {
      margin-top: 8px;
      font-size: 16px;
      font-weight: 600;
      color: var(--primary-text-color);
    }

    .description {
      margin-top: 8px;
    }
  }
}