@import "../../../../styles/variables";

.StadiumStats {
  @include border-around-element;
  padding: 16px;
  margin-top: 16px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 17px;
    }

    .viewAll {
      text-transform: capitalize;
    }
  }

  .content {
    margin-top: 16px;
    max-width: 100%;
    overflow: auto;

    .container {
      display: inline-flex;
      padding-bottom: 16px;

      .timelineCard {

      }

    }

  }
}
