@import "../../styles/variables";

.MyMediumList {

  .searchInput {
    margin-top: 16px;
    padding-left: 8px;
    padding-right: 8px;
  }

  .mediumList {
    margin-top: 16px;

    .mediumItem {
      padding: 8px;
      color: var(--primary-text-color);

      &:hover {
        cursor: pointer !important;
        color: $blue !important;
      }

      &:first-child {
        border-top-color: lightgrey;
        border-top-width: 1px;
        border-top-style: solid;
      }

      border-bottom-color: lightgrey;
      border-bottom-width: 1px;
      border-bottom-style: solid;

      .title {
        font-size: 16px;

      }

      .subtitle {
        font-size: 12px;
        color: gray;
      }
    }

  }
}
