@import "../../styles/variables";

.newOne {
  background: var(--main-background-color);
  height: 65px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: lightgrey;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;

  .container {
    @include centered;
    display: flex;
    width: $max-width;
    justify-content: space-between;

    .left {
      //background: blue;
      width: 240px;
      display: flex;
      align-items: center;

      .navMenu {
        margin-right: 8px;

        @media (min-width: $desktop-width) {
          display: none;
        }

        .navMenuIcon {
          font-size: 20px;
          margin-top: 4px;

          @media (min-width: $tablet-width) {
            display: none;
          }
        }
      }
    }

    .center {
      flex: 1;
      justify-content: center;
      display: none;

      @media (min-width: $tablet-width) {
        display: flex;
      }

      .searchInput {
        //width: 280px;
        //padding-left: 12px;
      }
    }

    .right {
      display: none;

      @media (min-width: $tablet-width) {
        width: 240px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      .createButton {
        margin-right: 16px;
        width: 100px;
      }

      .settingsContainer {
        cursor: pointer !important;
        width: 40px;
        height: 40px;
        max-width: 40px;
        overflow: hidden;
        border-radius: 20px;
        object-fit: cover;
        border-width: 1px;
        border-color: lightgrey;
        border-style: solid;

        .profileImage {
          width: 40px;
          height: 40px;
          max-width: 40px;
          overflow: hidden;
          border-radius: 20px;
          object-fit: cover !important;
        }
      }
    }

  }
}

.hideNav {
  display: none;
  background: var(--main-background-color);
  height: 65px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: lightgrey;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;

  @media (min-width: $tablet-width) {
    display: flex;
  }

  .container {
    @include centered;
    display: flex;
    width: $max-width;
    justify-content: space-between;

    .left {
      //background: blue;
      width: 240px;
      display: flex;
      align-items: center;

      .navMenu {
        margin-right: 8px;

        @media (min-width: $desktop-width) {
          display: none;
        }
      }
    }

    .center {
      flex: 1;
      justify-content: center;
      display: none;

      @media (min-width: $tablet-width) {
        display: flex;
      }

      .searchInput {
        width: 280px;
        //padding-left: 12px;
      }
    }

    .right {
      display: none;

      @media (min-width: $tablet-width) {
        width: 240px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      .createButton {
        margin-right: 16px;
        width: 100px;
      }

      .settingsContainer {
        cursor: pointer !important;
        width: 40px;
        height: 40px;
        max-width: 40px;
        overflow: hidden;
        border-radius: 20px;
        object-fit: cover;
        border-width: 1px;
        border-color: lightgrey;
        border-style: solid;

        .profileImage {
          width: 40px;
          height: 40px;
          max-width: 40px;
          overflow: hidden;
          border-radius: 20px;
          object-fit: cover !important;
        }
      }
    }

  }

}

.searchMore {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
