@import "../../../styles/variables";

.PostSource {
  width: 100%;
  border-top-color: var(--main-home-divider-color);
  border-top-width: 1px;
  border-top-style: solid;
  padding: 4px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .sourceInfo {
    display: flex;
    align-items: center;

    &:hover {

      .sourceImage {
        cursor: pointer;
      }

      .sourceTitle {
        cursor: pointer;
        color: $blue !important;
      }
    }

    .sourceImage {
      height: 40px;
      width: 30px;
      object-fit: contain;
    }

    .sourceTitle {
      padding-left: 8px;
      color: var(--primary-text-color);
      font-weight: 500;
    }

  }

}

.menuItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100px;

  .checkedIcon {
    color: $green;
  }
}
