@import "../../styles/variables";

.DraftScreen {

  .header {
    @include centered;
    margin-top: 16px;
    padding-left: 16px;
    display: flex;
    justify-content: space-between;
    text-transform: initial;
    text-decoration: none;

    @media (min-width: $tablet-width) {
      padding-left: 0px;
    }
  }
}
