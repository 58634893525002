@import "../../styles/variables";

.GoBack {
  @include centered;
  display: none;

  @media (min-width: $tablet-width) {
    display: block;
    margin-top: 16px;
  }
}
