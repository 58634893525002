@import "../../../styles/variables";

.RatingCard {
  background: var(--main-background-color);
  overflow: hidden;
  @include border-top-bottom;
  margin-bottom: $home-feed-card-margin-bottom !important;
  width: 100%;
  flex-direction: column;

  @media (min-width: $tablet-width) {
    margin: 16px auto;
    border-radius: 4px;
    border-style: solid;
    border-color: lightgrey;
    border-width: 1px;
    border-bottom-width: 1px !important;
  }

  .title {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: var(--main-home-divider-color);
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 8px;
    width: 100%;
    color: var(--primary-text-color);

    .tag {
      margin-bottom: 4px;
    }

    .outer {
      display: grid;
      grid-template: 1fr / 1fr;
      //place-items: center;

      .below {
        grid-column: 1 / 1;
        grid-row: 1 / 1;
        z-index: 2;
        background: var(--main-gradient);
        height: 100%;
        align-self: flex-end;
        display: flex;
        //flex-direction: column;
        justify-content: center;
        align-items: center;

        .belowTitle {
          color: red;
          font-weight: 600;
          //margin-top: 32px;
        }

        .belowButton {
          //background: blue;
        }
      }

      .top {
        grid-column: 1 / 1;
        grid-row: 1 / 1;
        z-index: 1;
      }
    }
  }
}
