@import "../../../styles/variables";

.SectionsTab {
  position: absolute;
  bottom: 0;
  width: 100%;
  max-width: $tablet-width;
  height: calc(100vh - 152px);
  display: flex;
  flex-direction: column;

  @media (min-width: $tablet-width) {
    position: inherit;
    height: auto;
    display: block;
  }

  .TemplateModal {

    .content {

      .previewBody {
      }
    }
  }
  .headerContainer {
    max-width: $content-width;
    margin-left: auto;
    margin-right: auto;
    position: sticky;
    top: 65px;
    z-index: 1;

    .headerWhiteboard {
      background: var(--main-background-color);

      @media (min-width: $tablet-width) {
        padding-top: 8px;
      }

      .messageHeader {
        @include border-around-element;
        padding: 12px 16px;

        @media (min-width: $tablet-width) {
          margin-top: 12px;
        }

        .topHeader {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .back {
            margin-right: 8px;
          }

          .titleHeader {
            flex: 1;
            text-align: left;
            font-size: 18px;
            font-weight: bold;
          }
        }

        .searchContainer {
          margin-top: 8px;
        }
      }

    }

  }

  .container {
    flex: 1 !important;
    //display: flex !important;
    flex-direction: column-reverse !important;
    overflow-y: auto !important;
    padding-bottom: 8px;
    //height: calc(100vh - 152px);
    //background: red !important;

    @media (min-width: $tablet-width) {
      display: block !important;
      max-width: $content-width;
      margin-left: auto;
      margin-right: auto;
      padding-bottom: 16px;
    }

    .chapters {
      @media (min-width: $tablet-width) {
        padding-top: 16px;
      }

      .checkButton {
        color: gray;
        margin-right: 8px;
      }

      .greenCheckButton {
        color: $green;
        margin-right: 8px;
      }

      .progressContainer {
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-bottom-color: lightgrey;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 8px;
        padding-top: 8px;

        @media (min-width: $tablet-width) {
          border-bottom-width: 0px;
          padding-left: 0px;
          padding-right: 0px;
          padding-bottom: 16px;
        }
      }

      .chapter {
        @media (min-width: $tablet-width) {
          margin-bottom: 16px;
        }

        > div {
          @media (min-width: $tablet-width) {
            margin-bottom: 16px;
          }
        }

        .info {
          @include border-bottom-element;
          padding: 16px 16px 16px 8px;
          display: flex;
          align-items: center;

          @media (min-width: $tablet-width) {
            @include border-around-element;
          }

          &:hover {
            cursor: pointer;
          }

          .number {
            font-weight: bold;
            padding-right: 4px;
          }

          .title {
            flex: 1;
          }

          .location {
            color: gray;
            padding-left: 8px;
            font-style: italic;
          }

          .count {
            margin-left: 8px;
          }

          .arrow {
            margin-left: 8px;
          }
        }

        .disableInfo {
          @extend .info;

          &:hover {
            cursor: not-allowed;
          }
        }

        .subChapterSection {

          .subChapter {


            @media (min-width: $tablet-width) {
              padding-left: 32px;
              margin-bottom: 8px;
            }

            .subInfo {
              @include border-bottom-element;
              padding: 16px;
              display: flex;
              align-items: center;

              @media (min-width: $tablet-width) {
                @include border-around-element;
              }

              &:hover {
                cursor: pointer;
              }

              .subNumber {
                font-weight: bold;
                padding-right: 4px;
              }

              .title {
                flex: 1;
              }

              .location {
                color: gray;
                padding-left: 8px;
                font-style: italic;
              }

              .count {
                margin-left: 8px;
              }

              .arrow {
                margin-left: 8px;
              }
            }

            .disableSubInfo {
              @extend .subInfo;

              &:hover {
                cursor: not-allowed;
              }
            }
          }

          .disableSubChapter {
            @extend .subChapter;
            color: lightgrey !important;

            &:hover {
              cursor: not-allowed;
            }
          }
        }

        .actions {
          display: flex;
          justify-content: flex-end;

          .addSubchapter {
            margin-bottom: 16px;
          }
        }

      }
    }

    .addTemplateButton {
      margin-top: 16px;
      width: 100%;
    }
  }
}

.content {

  .title {
    font-size: 18px;
    font-weight: bold;
  }

  .filterSearchContainer  {
    display: flex;
    align-items: center;
    margin-top: 8px;

    .filtersButton {
      margin-left: 8px;
      text-transform: capitalize;
    }
  }

  .filtersTitle {
    margin-top: 16px;
    font-size: 12px;
    color: gray;
  }

  .filtersContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .filterContainer {

      .filterTitle {
        font-size: 12px;
      }
    }

  }

  .templatesBody {
    margin-top: 16px;
    height: 240px;
    overflow-y: auto;

    .spinningContainer {
      text-align: center;
      padding-top: 16px;
    }

    .loadingContainer {

      .loadingCard {
        @include border-around-element;
        padding: 8px;
        margin-bottom: 16px;
      }
    }

    .nothingContainer {
      width: 100%;
      padding: 32px;
      text-align: center;
    }
  }

  .createButton {
    width: 100%;
    margin-top: 16px;
  }

}

.TemplateModal {

  .content {

    .previewHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .previewTitle {
        font-size: 18px;
        font-weight: bold;
      }

    }

    .previewInfo {
      margin-bottom: 16px;
    }

    .previewBody {
      max-height: 400px;
      overflow-y: auto;
      //margin-top: 16px;

      .chapter {

        .info {
          display: flex;
          margin-bottom: 8px;

          .number {
            font-weight: bold;
            padding-right: 4px;
          }

          .chapterInput {
            margin-left: 8px;
          }
        }

        .subChapterSection {

          .subChapter {
            padding-left: 16px;

            .subInfo {
              display: flex;
              //align-items: center;
              margin-bottom: 8px;

              .subNumber {
                //font-size: 21px;
                font-weight: bold;
                padding-right: 4px;
              }

              .location {
                color: gray;
                padding-left: 8px;
                font-style: italic;
              }
            }
          }
        }

        .actions {
          display: flex;
          justify-content: flex-end;

          .addSubchapter {
            margin-bottom: 16px;
          }
        }

      }
    }

    .previewFooter {
      margin-top: 16px;
      display: flex;
      justify-content: space-between;

      .useButton {
        width: 100%;
      }
    }
  }
}
