@import "../../../styles/variables";

.HomeContent {
  padding-top: 16px;

  .container {
    //height: calc(100vh - 220px);
    //overflow-y: auto;
  }
}
