@import "../../styles/variables";

.ClubScreen {
  //flex: 1;

  .container {
    max-width: $content-width;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: $tablet-width) {
      @include border-around-element;
      margin-top: 16px;
      padding: 16px 16px 1px 16px;
    }

    .backgroundImageContainer {
      position: relative;
      width: 100%;
      @include border-around-element;
      height: calc(100vw*0.33);

      @media (min-width: $tablet-width) {
        height: 245px;
      }

      .backgroundImage {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .backButton {
        //background: white;
        //color: black;
        margin: 8px;
        border-color: white;

        &:hover {
          border-color: $blue;
        }
      }
    }



    .backgroundImageSkeleton {
      width: 100%;
      height: 200px;
      border-radius: 4px;
    }

    .clubHeader {
      display: flex;
      padding-top: 16px;
      padding-left: 16px;
      padding-right: 16px;

      @media (min-width: $tablet-width) {
        padding-left: 0px;
        padding-right: 0px;
      }

      .clubImage {
        width: 60px;
        height: 60px;
        border-radius: 30px;

        @media (min-width: $tablet-width) {
          width: 80px;
          height: 80px;
          border-radius: 40px;
        }
      }

      .clubInfo {
        flex: 1;
        padding-left: 16px;
        //display: flex;
        //flex-direction: column;
        //justify-content: space-between;

        .name {
          font-size: 17px;
          font-weight: bold;
        }

        .aboutContainer {
          //flex: 1;

          .aboutText {
            text-decoration: underline !important;
          }

          .aboutButton {
            padding-left: 0px !important;
            padding-right: 0px !important;
          }
        }
      }

      .more {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;

        .moreButton {
          padding-left: 0px !important;
          padding-right: 0px !important;

          .membersText {
            text-decoration: underline !important;
          }
        }
      }

    }

    .sections {
      margin-top: 16px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 16px;
      row-gap: 16px;
      padding-left: 16px;
      padding-right: 16px;

      @media (min-width: $tablet-width) {
        padding-left: 0px;
        padding-right: 0px;
      }

      .item {
        border-radius: 4px;
        border-style: solid;
        border-width: 1px;
        border-color: lightgrey;
        padding: 16px;

        &:hover {
          cursor: pointer;
        }

        .red {
          filter: $filterRed;
        }

        .blue {
          filter: $filterBlue;
        }

        .green {
          filter: $filterGreen;
        }

        .yellow {
          filter: $filterYellow;
        }

        .purple {
          filter: $filterPurple;
        }

        .icon {
          width: 30px;
          height: 30px;
        }

        .title {
          margin-top: 8px;
          font-size: 16px;
          font-weight: 500;
        }

        .description {
          display: none;
        }
      }
    }
  }
}
