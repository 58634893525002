@import "../../styles/variables";

.EditProfileScreen {

  .container {
    @include centered;
    max-width: $content-width;

    .tabContainer {
      @media (min-width: $tablet-width) {
        @include border-around-element;
        margin-top: 16px;
      }

      .editContainer {
        text-align: center;
        padding: 16px;

        .header {
          display: flex;
          justify-content: center;

          .imageContainer {
            display: flex;
            flex-direction: column;
            align-items: center;

            .image {
              height: 140px;
              width: 140px;
              border-radius: 70px;
              font-size: 100px;
              font-weight: 600;
              display: flex;
              justify-content: center;
              align-items: center;
              object-fit: cover;
              border-color: lightgray;
              border-width: 1px;
              border-style: solid;
            }

          }

        }

      }

      .privateContainer {
        padding: 16px 16px 48px 16px;

        .privateTitle {
          font-size: 24px;
          color: var(--primary-text-color);
        }

        .privacyCheckbox {
          margin-top: 16px;

          .privacyCheckboxTitle {
            color: var(--primary-text-color);
          }
        }

        .privacySubtitle {
          margin-top: 4px;
        }

        .savePrivateData {
          margin-top: 16px;

          .savePrivateButton {

          }

        }
      }

      .body {

        .iconColor {
          color: var(--tertiary-text-color);
        }

        .usernameContainer {
          display: flex;

          .username {
            flex: 1;
            padding-right: 32px;
          }

          .check {
            margin-left: 8px;
          }

          .available {
            @extend .check;
            background: $green;
            border-color: $green;
            color: white;
          }

          .taken {
            @extend .check;
            background: $red;
            border-color: $red;
            color: white;
          }
        }

      }

    }

  }
}
