
.DoneCard {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  .container {
    text-align: center;

    .title {
      font-size: 26px;
      font-weight: 600;
      color: var(--primary-text-color);
    }

    .subtitle {
      color: var(--primary-text-color);
      font-size: 16px;
      margin-top: 24px;
    }

    .actions {

      .doneButton {
        margin-top: 24px;
        height: fit-content;
      }
    }
  }

}
