@import "../../styles/variables";

.RequestsScreen {
  //min-height: calc(100vh - 134px);

  .spinningContainer {
    margin-top: 16px;
    text-align: center;
  }

  .container {
    @include centered;
    max-width: $content-width;

    @media (min-width: $tablet-width) {
      margin-top: 16px;
    }

  }
}
