
.ImageModal {

}

.imageModal {
  width: 256px !important;

  .modalTitleContainer {
    padding: 16px;
    display: flex;
    align-items: center;

    .title {
      flex: 1;
      padding-left: 8px;
    }
  }

  .cropContainer {
    height: 256px;
    width: 100%;
  }

  .sliderContainer {
    display: flex;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 16px;
    padding-left: 8px;
    padding-right: 8px;

    .slider {
      flex: 1;
      margin-left: 24px;
      margin-right: 24px;
    }
  }
}
