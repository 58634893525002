@import "../../styles/variables";

.ClubStoryScreen {
  display: flex;
  flex-direction: column;
  align-items: center;

  .headerContainer {
    width: 100%;
    max-width: $content-width;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;

    @media (min-width: $tablet-width) {
      position: sticky;
      top: 65px;
      z-index: 1;
    }

    .headerWhiteboard {
      background: var(--main-background-color);

      @media (min-width: $tablet-width) {
        padding-top: 8px;
      }

      .messageHeader {
        padding: 12px 16px;

        @media (min-width: $tablet-width) {
          @include border-around-element;
          margin-top: 12px;
        }

        .topHeader {
          display: flex;
          align-items: center;

          .titleHeader {
            flex: 1;
            display: flex;
            align-items: center;
            font-weight: bold;
            min-height: 44px;
            padding: 0px !important;
            margin: 0px !important;

            @media (min-width: $tablet-width) {
              font-size: 18px;
            }

            & > div {
              padding: 0px !important;
            }
          }
        }

        .searchContainer {
          margin-top: 8px;
        }
      }

      .messageRequest {
        @include border-around-element;
        padding: 8px 16px;
        margin-top: 8px;
        display: none;
      }
    }

  }

  .chatHeader {
    min-height: 66px;
    display: flex;
    align-items: center;
    padding: 12px;
    width: 100%;
    max-width: $tablet-width;
    @include border-around-element;

    @media (min-width: $tablet-width) {
      display: flex;
      text-align: left;
      margin-top: 16px;
      align-items: center;
    }

    .content {
      flex: 1;
      display: flex;
      align-items: center;
      padding-left: 16px;
      padding-right: 16px;

      .name {
        font-weight: bold;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .info {
      font-size: 24px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .tabs {
    width: 100%;
    max-width: $tablet-width;
  }
}

.uploadOptionItem {
  display: flex;
  align-items: center;

  .uploadIcon {
    font-size: 50px;
  }

  .uploadText {
    margin-left: 8px;
  }
}

.displayNote {
  display: flex;
  flex-direction: column;
  align-items: center;

  .container {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-width: $tablet-width;
    height: calc(100vh - 65px);
    display: flex;
    flex-direction: column;

    @media (min-width: $tablet-width) {
      position: relative;
      height: calc(100vh - 172px);
      margin-top: 8px;
    }

    .header {
      padding: 16px;

      .titleContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
          flex: 1;
          text-align: left;
          font-size: 17px;
          font-weight: bold;
        }
      }

      .subtitle {
        display: flex;

        .location {
          margin-right: 8px;
        }

        .categories {
          display: flex;

          .category {
            margin-right: 8px;
          }
        }
      }
    }

    .loadingHeader {
      padding: 16px;

      .loadingItem {
        width: 100%;
      }
    }

    .messagesContainer {
      border-top-color: lightgrey;
      border-top-width: 1px;
      border-top-style: solid;
      flex: 1 !important;
      display: flex !important;
      flex-direction: column !important;
      overflow-y: auto !important;
      padding-bottom: 8px;

      .loadingContent {
        padding: 16px;
      }

      .text {
        padding: 16px;
        white-space: pre-line;
      }

      .quoteThoughtContainer {
        padding: 16px;
        display: flex;

        .quoteContainer {
          flex: 1;

          .title {
            font-weight: 500;
            text-transform: capitalize;
          }

          .body {
            margin-top: 16px;
            white-space: pre-wrap;
          }
        }
      }

      .questionAnswerContainer {
        padding: 16px;

        .questionContainer {
          .title {
            font-weight: 500;
            text-transform: capitalize;
          }

          .body {
            margin-top: 16px;
            white-space: pre-wrap;
          }
        }

        .answerContainer {
          margin-top: 16px;

          .showAnswer {
            text-transform: capitalize;
          }

          .title {
            font-weight: 500;
            text-transform: capitalize;
          }

          .body {
            margin-top: 16px;
            white-space: pre-wrap;
          }
        }
      }

      .spinningContainer {
        text-align: center;
        padding-top: 16px;
      }

    }

  }
}

.content {

  .title {
    font-size: 18px;
    font-weight: bold;
  }

  .filterSearchContainer  {
    display: flex;
    align-items: center;
    margin-top: 8px;

    .filtersButton {
      margin-left: 8px;
      text-transform: capitalize;
    }
  }

  .filtersTitle {
    margin-top: 16px;
    font-size: 12px;
    color: gray;
  }

  .filtersContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .filterContainer {

      .filterTitle {
        font-size: 12px;
      }
    }

  }

  .templatesBody {
    margin-top: 16px;
    height: 240px;
    overflow-y: auto;

    .spinningContainer {
      text-align: center;
      padding-top: 16px;
    }

    .loadingContainer {

      .loadingCard {
        @include border-around-element;
        padding: 8px;
        margin-bottom: 16px;
      }
    }

    .nothingContainer {
      width: 100%;
      padding: 32px;
      text-align: center;
    }
  }

  .createButton {
    width: 100%;
    margin-top: 16px;
  }

}

.MyNotesModal {

  .content {

    .previewHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .previewTitle {
        font-size: 18px;
        font-weight: bold;
      }

    }

    .previewInfo {
      margin-bottom: 16px;
    }

    .previewBody {
      max-height: 400px;
      overflow-y: auto;
      //margin-top: 16px;

      .chapter {

        .info {
          display: flex;
          margin-bottom: 8px;

          .number {
            font-weight: bold;
            padding-right: 4px;
          }

          .chapterInput {
            margin-left: 8px;
          }
        }

        .subChapterSection {

          .subChapter {
            padding-left: 16px;

            .subInfo {
              display: flex;
              //align-items: center;
              margin-bottom: 8px;

              .subNumber {
                //font-size: 21px;
                font-weight: bold;
                padding-right: 4px;
              }

              .location {
                color: gray;
                padding-left: 8px;
                font-style: italic;
              }
            }
          }
        }

        .actions {
          display: flex;
          justify-content: flex-end;

          .addSubchapter {
            margin-bottom: 16px;
          }
        }

      }
    }

    .previewFooter {
      margin-top: 16px;
      display: flex;
      justify-content: space-between;

      .useButton {
        width: 100%;
      }
    }
  }
}
