@import "src/styles/variables";

.StadiumTabIcon {
  width: calc(100vw * (1/4));
  display: flex;
  //align-items: center;
  justify-content: center;

  @media (min-width: $tablet-width) {
    width: calc(800px * (1/4));
  }

  //&:hover {
  //  filter: invert(73%) sepia(65%) saturate(5192%) hue-rotate(178deg) brightness(93%) contrast(84%);
  //}
  &:hover {
    .icon {
      filter: invert(73%) sepia(65%) saturate(5192%) hue-rotate(178deg) brightness(93%) contrast(84%);
    }
  }

  .icon {
    width: 22px;
    height: auto;
    object-fit: cover;
  }

  .active {
    filter: invert(73%) sepia(65%) saturate(5192%) hue-rotate(178deg) brightness(93%) contrast(84%);
  }

  .title {
    margin-left: 8px;
    text-transform: capitalize;


  }
}
