@import "../../../styles/variables";

.ChatCard {
  //padding: 8px;
  width: 100%;

  @media (min-width: $tablet-width) {
    padding-left: 12px;
    padding-right: 12px;
  }

  .myTextMessageContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 2px;

    .more {
      display: none;
      margin-right: 8px;

      &:hover {
        cursor: pointer;
        color: $blue;
      }
    }

    &:hover {
      .more {
        display: block;
      }
    }

    .myTextMessage {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: auto;
      max-width: 80%;

      .textTop {
        background: $chat-blue;
        color: white;
        height: fit-content;
        padding: 12px;
        border-top-right-radius: 16px;
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
        width: fit-content;
        white-space: pre-wrap;
        margin-right: 4px;
      }

      .text {
        background: $chat-blue;
        color: white;
        height: fit-content;
        padding: 12px;
        border-radius: 16px 8px 8px 16px;
        width: fit-content;
        white-space: pre-wrap;
        margin-right: 4px;
      }

      .textBottom {
        background: $chat-blue;
        color: white;
        height: fit-content;
        padding: 12px;
        border-bottom-right-radius: 16px;
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
        width: fit-content;
        white-space: pre-wrap;
        margin-right: 4px;
      }

      .timeStamp {
        display: flex;
        justify-content: flex-end;
        font-size: 10px;
        position: relative;
      }
    }

  }

  .myFileMessageContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 2px;
    //max-width: 30%;
    //margin-left: auto;

    .more {
      display: none;
      margin-right: 8px;

      &:hover {
        cursor: pointer;
        color: $blue;
      }
    }

    &:hover {
      .more {
        display: block;
      }
    }

    .myTextMessage {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: auto;
      max-width: min-content;

      .image {
        width: 300px;
        > img {
          border-radius: 4px;
        }
      }

      .textTop {
        background: $chat-blue;
        color: white;
        height: fit-content;
        padding: 12px;
        border-top-right-radius: 16px;
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
        width: fit-content;
        white-space: pre-wrap;
        margin-right: 4px;
      }

      .text {
        background: $chat-blue;
        color: white;
        height: fit-content;
        padding: 12px;
        border-radius: 16px 8px 8px 16px;
        width: fit-content;
        white-space: pre-wrap;
        margin-right: 4px;
      }

      .textBottom {
        background: $chat-blue;
        color: white;
        height: fit-content;
        padding: 12px;
        border-bottom-right-radius: 16px;
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
        width: fit-content;
        white-space: pre-wrap;
        margin-right: 4px;
      }

      .timeStamp {
        display: flex;
        justify-content: flex-end;
        font-size: 10px;
        position: relative;
      }
    }

  }

  .otherTextMessageContainer {
    display: flex;
    align-items: center;
    margin-top: 2px;

    .more {
      display: none;
      margin-left: 8px;

      &:hover {
        cursor: pointer;
        color: $blue;
      }
    }

    &:hover {
      .more {
        display: block;
      }
    }

    .imageIcon {
      width: 30px;
      height: 30px;
      border-radius: 15px;
      margin-bottom: 12px;
    }

    .otherTextMessage {
      margin-left: 4px;
      max-width: 80%;

      .displayName {
        font-size: 10px;
      }

      .textTop {
        background: $chat-gray;
        color: white;
        height: fit-content;
        padding: 12px;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
        width: fit-content;
        white-space: pre-wrap;
      }

      .text {
        background: $chat-gray;
        color: white;
        height: fit-content;
        padding: 12px;
        border-radius: 8px 16px 16px 8px;
        width: fit-content;
        white-space: pre-wrap;
      }

      .textBottom {
        background: $chat-gray;
        color: white;
        height: fit-content;
        padding: 12px;
        border-bottom-left-radius: 16px;
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
        width: fit-content;
        white-space: pre-wrap;
      }

      .timeStamp {
        display: flex;
        justify-content: flex-start;
        font-size: 10px;
        position: relative;
      }
    }
  }

  .otherFileMessageContainer {
    display: flex;
    align-items: center;
    margin-top: 2px;
    //max-width: 30%;
    //margin-right: auto;

    .more {
      display: none;
      margin-left: 8px;

      &:hover {
        cursor: pointer;
        color: $blue;
      }
    }

    &:hover {
      .more {
        display: block;
      }
    }

    .imageIcon {
      width: 30px;
      height: 30px;
      border-radius: 15px;
      margin-bottom: 12px;
    }

    .otherTextMessage {
      margin-left: 4px;
      //max-width: 80%;
      max-width: min-content;

      .image {
        width: 300px;

        > img {
          border-radius: 4px;
        }
      }

      .displayName {
        font-size: 10px;
      }

      .textTop {
        background: $chat-gray;
        color: white;
        height: fit-content;
        padding: 12px;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
        width: fit-content;
        white-space: pre-wrap;
      }

      .text {
        background: $chat-gray;
        color: white;
        height: fit-content;
        padding: 12px;
        border-radius: 8px 16px 16px 8px;
        width: fit-content;
        white-space: pre-wrap;
      }

      .textBottom {
        background: $chat-gray;
        color: white;
        height: fit-content;
        padding: 12px;
        border-bottom-left-radius: 16px;
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
        width: fit-content;
        white-space: pre-wrap;
      }

      .timeStamp {
        display: flex;
        justify-content: flex-start;
        font-size: 10px;
        position: relative;
      }
    }
  }
}
