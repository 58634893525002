@import "../../../../styles/variables";

.StoryCard {
  border-style: solid !important;
  border-width: 1px !important;
  border-color: lightgrey !important;
  border-radius: $border-radius !important;
  width: 260px !important;
  padding: 16px;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-right: 24px;

  .content {
    flex: 1;

    .title {
      font-size: 16px;
      color: var(--primary-text-color);
    }

    .startingContainer {

      .startingTitle {
        font-size: 12px;
      }

      .startingDate {
        font-weight: bold;
        @extend .title;
        text-transform: capitalize;
      }
    }

    .medium {
      margin-top: 16px;

      .image {
        width: 100%;
        height: 100%;
        max-height: 140px;
        object-fit: contain;
      }

      .mediumTitle {
        margin-top: 4px;
        @extend .title;
      }
    }

    .membersContainers {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 8px;

      .profileImage {
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }

      .additionalMembers {
        width: 24px;
        height: 24px;
      }
    }
  }

  .joinButton {
    margin-top: 16px;
    width: 100%;
  }
}