@import "../../styles/variables";

.ClubStoriesCreateScreen {
  .container {
    @include centered;
    @include border-around-element;
    max-width: $content-width;
    padding: 16px;

    @media (min-width: $tablet-width) {
      margin-top: 16px;
    }

    .header {
      display: flex;
      align-items: center;

      .goBack {
        margin-right: 16px;
        //display: none;

        @media (min-width: $tablet-width) {
          display: block;
        }
      }

      .name {
        font-size: 18px;
      }
    }

    .form {
      margin-top: 24px;

      .switchRateSettings {
        margin-top: 8px;
        margin-bottom: 16px;
      }

      .actions {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .delete {

        }
      }

      .startDateContainer {
        display: flex;
        align-items: center;

        .chapterPreview {
          margin-left: 16px;
        }
      }
    }
  }
}

.friendLabelOption {
  display: flex !important;
  align-items: center;
  padding: 4px 8px;

  .image {
    width: 30px !important;
    height: 30px !important;
    //border-radius: 15px !important;
  }

  .name {
    margin-left: 4px;
  }
}
