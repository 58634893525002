@import "../../../styles/variables";

.PostCardSkeleton {
  overflow: hidden;
  @include border-top-bottom;
  margin-bottom: $home-feed-card-margin-bottom !important;
  flex-direction: column;

  @media (min-width: $tablet-width) {
    margin: 16px auto;
    border-radius: 4px;
    border-style: solid;
    border-color: lightgrey;
    border-width: 1px;
    border-bottom-width: 1px !important;
  }

  .postHeader {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px 12px;

    .profileImage {
      height: 34px;
      width: 34px;
      border-radius: 17px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .userInfo {
      padding-left: 12px;
    }
  }

  .title {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: var(--main-home-divider-color);
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 8px;
    width: 100%;
  }

  .body {
    //height: 100vw;
    overflow: hidden;
    object-fit: cover;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;

    @media (min-width: $tablet-width) {
      //height: 300px;
      width: 100%;
    }
  }
}
