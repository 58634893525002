@import "../../../../../styles/variables";

.GoalHeader {
  flex: 1;
  @include border-bottom-element;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .image {
    margin-top: 4px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  .title {
    margin-top: 4px;
    //flex: 1;
    padding-left: 8px;
    font-weight: bold;
  }

  .gameTime {
    margin-top: 4px;
  }

  .scoreContainer {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    .teamImage {
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }

    .score {
      margin-left: 8px;
      margin-right: 8px;
    }

    .divider {
      //margin-left: 8px;
      //margin-right: 8px;
    }
  }
}
