@import "src/styles/variables";

.PodcastsGridCard2 {
  grid-column: span 1;
  padding: 0px !important;
  margin: 0px !important;
  width: 100%;
  height: 100%;
  position: relative;

  & > span {
    width: 100% !important;
    height: 100% !important;
  }

  .image {
    width: 100%;
    overflow: hidden;
    object-fit: cover;

    &:hover {
      cursor: pointer;
    }

    @media (min-width: $tablet-width) {
      @include border-element;
    }
  }

  .episodeTitle {
    position: absolute;
    bottom: 0;
    background: rgba(0,0,0,0.8);
    color: white;
    font-weight: 600;
    font-size: 16px;
    margin: 8px;
    border-radius: 4px;
    padding: 8px;
  }
  .mediumBackground {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.4;
  }

  .removeContainer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .removeButton {
      border-color: red;
      background: red;
      color: white;
    }
  }
}
