@import "../../../styles/variables";

.PreviewTemplate {
  padding: 16px;

  .templateTitle {
    font-size: 17px;
  }

  .templateInfo {
    margin-top: 16px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    //grid-auto-rows: 180px;
    //grid-column-gap: 2vw;
    grid-row-gap: 16px;

    .infoContainer {
      grid-column: span 1;

      .infoTitle {
        color: gray;
      }
    }
  }

  .chapters {
    margin-top: 16px;

    .chapter {

      .info {
        display: flex;
        margin-bottom: 8px;

        .number {
          font-weight: bold;
          padding-right: 4px;
        }

        .location {
          color: gray;
          padding-left: 8px;
          font-style: italic;
        }

        .chapterInput {
          margin-left: 8px;
        }
      }

      .subChapterSection {

        .subChapter {
          padding-left: 16px;

          .subInfo {
            display: flex;
            margin-bottom: 8px;

            .subNumber {
              font-weight: bold;
              padding-right: 4px;
            }

            .location {
              color: gray;
              padding-left: 8px;
              font-style: italic;
            }
          }
        }
      }

      .actions {
        display: flex;
        justify-content: flex-end;

        .addSubchapter {
          margin-bottom: 16px;
        }
      }

    }
  }
}
