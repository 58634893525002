@import "../../../../styles/variables";

.Welcome {
  @include border-around-element;
  padding: 16px;
  font-size: 21px;
  display: flex;

  .textContainer {
    flex: 1;

    .greeting {

      .name {
        padding-left: 6px;
        font-weight: bold;
      }
    }

    .takeTourButton {
      margin-top: 16px;
    }

    .event {

      .team1 {
        font-weight: bold;
      }

      .team2 {
        padding-left: 6px;
        font-weight: bold;
      }
    }

  }

  .stadiumImageContainer {
    display: flex;

    .stadiumImage {
      width: 200px;
      height: auto;
      object-fit: cover;
      margin-right: 32px;
    }
  }
}
