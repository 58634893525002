@import "../../../styles/variables";

.QuizModal {

}

.quizContent {
  @include border-around-element;

  .header {
    @include border-bottom-element;
    padding: 16px;
  }

  .quizBody {
    padding: 16px;
    text-align: center;

    .congrats {
      margin-top: 16px;
      text-transform: uppercase;
      font-weight: bold;
    }

    .yourScore {
      margin-top: 16px;
    }

    .scoreContainer {
      margin-top: 32px;
      margin-bottom: 32px;

      .score {
        font-size: 70px;
      }

      .totalQuestions {
        font-size: 36px;
      }
    }

    .question {
      margin-top: 32px;
      font-size: 18px;
      font-weight: 600;
    }

    .options {
      margin-top: 32px;

      .option {
        margin-top: 16px;
        width: 280px;
      }

      .correctOption {
        @extend .option;
        background: $green !important;
        border-color: $green !important;
        color: white !important;
      }

      .selectedOption {
        @extend .option;
        background: $blue;
        border-color: $blue;
        color: white;
      }

      .incorrectOption {
        @extend .option;
        background: $red !important;
        border-color: $red !important;
        color: white !important;
      }
    }
  }

  .footer {
    @include border-top-element;
    padding: 16px;
    display: flex;
    justify-content: space-between;
  }
}

