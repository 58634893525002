@import "../../styles/variables";

.Rating10 {
  display: flex;

  .progressRatingContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 24px;
    align-items: center;

    .progressTitle {
      font-size: 12px;
    }

    .ratingsCount {
      color: var(--primary-text-color);
      font-size: 12px;
      font-weight: 500;
      margin-top: 8px;
    }

    .ratingsTitle {
      color: var(--primary-text-color);
      font-size: 12px;
      font-weight: 500;
      text-transform: capitalize;
    }

    .rateButton {
      margin-top: 4px;
    }
  }

  .leftContainer {
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .rightContainer {
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

}

.ratingTitleInput {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;

  .ratingTextArea {
    font-size: 16px;
  }
}

.ratingDisplay {
  margin-top: 8px;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .ratingDisplayValue {
    font-size: 40px;
    font-weight: 500;
    color: var(--primary-text-color);

    .outOfDisplay {
      color: gray;
      font-size: 14px;
    }
  }

  .ratingValue {
    font-size: 22px;
    font-weight: 500;
    color: black;

    .outOf {
      color: gray;
      font-size: 14px;
    }
  }

  .ratingIcon {
    margin-top: 16px;
    width: 50px;
    height: 50px;
  }

  .ratingTitle {
    margin-top: 16px;
    color: black;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
  }

  .ratingDescription {
    margin-top: 8px;
    font-size: 14px;
    color: gray;
  }

}

.ratingSliderContainer {
  width: 100%;
  display: flex;
  justify-content: center;

  .ratingSlider {
    margin-top: 40px !important;
    width: 400px;
    text-align: center;
  }
}

.tagsContainer {
  width: 100%;

  @media (min-width: $tablet-width) {
    max-width: 400px;
  }

  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;

  .checkTag {
    font-size: 14px !important;
    margin-top: 8px !important;
  }
}

.moreFeedbackContainer {
  width: 100%;

  @media (min-width: $tablet-width) {
    max-width: 400px;
  }

  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;

  .feedBackContainer {

    .feedbackTextContainer {
      display: flex;
      align-items: center;

      .feedbackText {
        margin-bottom: 0px !important;
      }
    }

    .spoilerContainer {
      display: flex;
      align-items: center;
      margin-top: 16px;

      .spoilerSwitch {
        margin-left: 8px;
      }
    }
  }

}

.actions {
  width: 100%;
  text-align: center;

  .saveButton {
    margin-top: 40px !important;
    width: 200px;
    text-transform: uppercase;
  }

}

.TextModal {

  .textModalBody {

    .textModalContainer {

      .textArea {
        font-size: 16px;
      }
    }

    .saveButton {
      font-weight: normal;
    }
  }
}
