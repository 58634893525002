@import "../../styles/variables";

.HomeScreen {

  .spinningContainer {
    text-align: center;
  }

  .modalInterests {

  }

  .modalBody {

    .imageChoice {
      width: 20px;
    }
  }

}
