@import "../../styles/variables";

.SummaryPreview {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;

  &:hover {
    cursor: pointer;

    .summaryBody {
      color: $blue;
    }
  }

  @media (min-width: $tablet-width) {
    //height: 360px;
    @include border-element;
  }

  .summaryHeader {
    height: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 4px;

    @media (min-width: $tablet-width) {
      height: 30px;
    }

    .summaryLabel {
      font-style: italic;
      //background: linear-gradient(180deg,rgba(255,255,255,0) 0%, rgba(255, 255, 0, 0.9) 100%);
      background: rgba(46,204,113, 0.8);
      padding-left: 2px;
      padding-right: 2px;
      font-size: 10px;
      color: black;

      @media (min-width: $tablet-width) {
        padding-left: 4px;
        padding-right: 4px;
        font-size: 12px;
      }
    }
  }

  .summaryBody {
    flex: 1;
    display: flex;
    //align-items: center;
    padding-left: 8px;
    padding-right: 8px;
    color: black;
    font-size: 12px;
    overflow-y: hidden;

    @media (min-width: $tablet-width) {
      font-size: 16px;
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  .summaryFooter {
    height: 34px;
    display: flex;
    align-items: center;
    padding: 2px 8px 4px;

    @media (min-width: $tablet-width) {
      height: 40px;
      padding: 4px 12px 8px;
    }

    .summarySourceImage {
      height: 100%;
      object-fit: contain;
    }

    .summarySourceTitle {
      font-style: italic;
      padding-left: 4px;
      flex: 1;
      font-size: 10px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      color: black;

      @media (min-width: $tablet-width) {
        //font-size: 14px;
      }
    }
  }
}

