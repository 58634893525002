@import "../../../styles/variables";

.MainItem {
  display: flex;
  align-items: center;
  padding-top: 16px;
  flex-direction: column;

  @media (min-width: $tablet-width) {
    @include centered;
    flex-direction: row;
  }

  .textContainer {
    flex: 1;
    color: var(--primary-text-color);
    padding-left: 16px;
    padding-right: 16px;

    @media (min-width: $tablet-width) {
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 24px;
    }

    .title {
      font-size: 36px;
      font-weight: 700;
      margin-bottom: 0px;
      color: var(--primary-text-color);
      text-transform: uppercase;

      @media (min-width: $tablet-width) {
        margin-bottom: 24px;
      }

      .find {
        background: linear-gradient(180deg,rgba(255,255,255,0) 50%, rgba($red,0.7) 50%);
      }

      .share {
        background: linear-gradient(180deg,rgba(255,255,255,0) 50%, rgba($green,0.7) 50%);
      }

      .create {
        background: linear-gradient(180deg,rgba(255,255,255,0) 50%, rgba($orange,0.7) 50%);
      }

      @media (min-width: $tablet-width) {
        font-size: 66px;
      }
    }

    .subtitle {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 24px;

      .highlight {
        color: $blue;
        font-weight: 800;
      }

      @media (min-width: $tablet-width) {
        font-size: 32px;
      }
    }

    .actions {
      display: none;

      @media (min-width: $tablet-width) {
        display: initial;
      }

      .actionButton {

      }

    }

  }

  .imageContainer {
    flex: 1;
    padding-left: 32px;
    padding-right: 32px;

    @media (min-width: $tablet-width) {
      padding-left: 32px;
    }

    .image {
      width: 100%;
      height: 400px;
      object-fit: contain;

      @media (min-width: $tablet-width) {
        height: 600px;
      }
    }

    .options {
      background: blue;

      //.option {
      //  font-size: 28px;
      //  font-weight: bold;
      //  text-transform: capitalize;
      //}
    }
  }
}

.movies {
  background: linear-gradient(180deg,rgba(255,255,255,0) 50%, rgba($blue,0.7) 50%);
}

.series {
  background: linear-gradient(180deg,rgba(255,255,255,0) 50%, rgba($red,0.7) 50%);
}

.podcasts {
  background: linear-gradient(180deg,rgba(255,255,255,0) 50%, rgba($green,0.7) 50%);
}

.books {
  background: linear-gradient(180deg,rgba(255,255,255,0) 50%, rgba($orange,0.7) 50%);
}
