@import "../../../../../styles/variables";

.CardHeader {
  @include border-bottom-element;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .card {
    height: 28px;
    width: 20px;
    border-radius: 5px;
    margin-top: 12px;
  }

  .red {
    background: red;
  }

  .yellow {
    background: yellow;
  }

  .green {
    background: green;
  }

  .image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  .title {
    //margin-top: 16px;
    text-transform: capitalize;
    flex: 1;
    margin-top: 12px;
  }

  .gameTime {

  }
}
