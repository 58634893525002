@import "../../styles/variables";

.ClubsEditScreen {

  .imageModal {
    background: green;
  }

  .container {
    @include centered;
    @include border-around-element;
    max-width: $content-width;
    padding: 16px;

    @media (min-width: $tablet-width) {
      margin-top: 16px;
    }

    .header {
      display: flex;
      align-items: center;

      .goBack {
        margin-right: 16px;
        display: none;

        @media (min-width: $tablet-width) {
          display: block;
        }
      }

      .name {
        font-size: 18px;
      }
    }

    .form {
      margin-top: 24px;

      .clickToEdit {
        font-size: 12px;
        margin-top: 8px;
        margin-bottom: 16px;
        //font-style: italic;
      }

      .cropContainer {
        height: 256px;
        width: 100%;
      }

      .sliderContainer {
        display: flex;
        align-items: center;
        margin-top: 16px;
        margin-bottom: 16px;

        .slider {
          flex: 1;
          margin-left: 24px;
          margin-right: 24px;
        }
      }

      .backgroundImage {
        width: 100%;
        @include border-around-element;

        &:hover {
          cursor: pointer;
        }
      }

      .clubImage {
        width: 80px;
        height: 80px;
        border-radius: 40px;

        &:hover {
          cursor: pointer;
        }
      }

      .friendLabel {
        border-color: lightgrey;
        border-width: 1px;
        border-style: solid;
        display: flex;
        align-items: center;
        padding: 4px 8px;
        border-radius: 3px;
        margin-right: 8px;
        margin-top: 2px;
        margin-bottom: 2px;

        .image {
          width: 30px;
          height: 30px;
          border-radius: 15px;
        }

        .name {
          margin-left: 4px;
        }

        .closeIcon {
          margin-left: 8px;

          &:hover {
            cursor: pointer;
            color: $blue;
          }
        }
      }
    }
  }

  .container > div > img {
    //opacity: 1 !important;
  }
}

.friendLabelOption {
  display: flex !important;
  align-items: center;
  padding: 4px 8px;

  //&:hover {
  //  background: lightblue;
  //  cursor: pointer;
  //}

  .image {
    width: 30px !important;
    height: 30px !important;
    border-radius: 15px !important;
  }

  .name {
    margin-left: 4px;
  }

}

.rect {
  width: 800px !important;
}

.square {
  width: 256px !important;
}

.imageModal {
  //width: 800px !important;

  .modalTitleContainer {
    padding: 16px;
    display: flex;
    align-items: center;

    .title {
      flex: 1;
      padding-left: 8px;
    }
  }

  .cropContainer {
    height: 256px;
    width: 100%;
  }

  .sliderContainer {
    display: flex;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 16px;
    padding-left: 8px;
    padding-right: 8px;

    .slider {
      flex: 1;
      margin-left: 24px;
      margin-right: 24px;
    }
  }
}
