@import "../../../styles/variables";

.ClubCardSkeleton {

  .item {
    width: 100% !important;
    height: 100% !important;
    border-radius: 4px;
  }
}
