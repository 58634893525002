@import "../../../styles/variables";

.DraftQuote {
  background: white;
  overflow: hidden;
  @include border-top-bottom;
  margin-bottom: $home-feed-card-margin-bottom !important;
  width: 100%;
  flex-direction: column;

  @media (min-width: $tablet-width) {
    width: $post-width;
    margin: 16px auto;
    border-radius: 4px;
    border-style: solid;
    border-color: lightgrey;
    border-width: 1px;
    border-bottom-width: 1px !important;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px 16px;


    .profileImage {
      height: 32px;
      width: 32px;
      border-radius: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .username {
      padding-left: 12px;
    }
  }

  .title {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: var(--main-home-divider-color);
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 8px;
    width: 100%;
  }

  .body {
    height: 100vw;
    overflow: hidden;
    object-fit: cover;

    @media (min-width: $tablet-width) {
      height: $post-width;
      width: 100%;
    }
  }

  .source {
    width: 100%;
    border-top-color: var(--main-home-divider-color);
    border-top-width: 1px;
    border-top-style: solid;
    border-bottom-color: var(--main-home-divider-color);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .sourceInfo {
      display: flex;
      align-items: center;

      .sourceImage {
        height: 40px;
        width: 30px;
        object-fit: contain;
      }

      .sourceTitle {
        padding-left: 8px;
      }

    }

  }

  .footer {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 8px;

    .stats {
      color: black;
      font-weight: 600;
    }

    .title {
      .titleUsername {
        color: black;
        font-weight: 600;
      }

      .titleText {
        padding-left: 8px;
      }
    }

    .timeStamp {
      margin-top: 4px;
      font-size: 12px;
    }
  }
}
