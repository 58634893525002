
.ColorPaletteModal {
  padding: 16px;
  //color: black;

  @mixin title {
    font-weight: 600;
    font-size: 16px;
  }

  .colorTitle {
    @include title;
  }

  .colors {
    margin-top: 8px;
    display: grid;
    grid-auto-rows: auto;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    justify-items: center;
    grid-template-columns: repeat(12, 1fr);

    .palette {
      border-color: lightgrey;
      border-style: solid;
      border-width: 1px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      grid-column: span 2;
    }

    .colorItem {
      width: 30px;
      height: 30px;
      border-radius: 4px;
      cursor: pointer;
    }

    .selected {
      border-width: 3px !important;
      border-color: red !important;
    }
  }

  .opacityTitle {
    @include title;
    margin-top: 16px;
  }

  .opacity {
    margin-top: 8px;
  }

  .actions {
    margin-top: 16px;
  }
}
