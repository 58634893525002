@import "../../styles/variables";

.StorynotesScreen {

  .headerContainer {
    max-width: $content-width;
    margin-left: auto;
    margin-right: auto;
    position: sticky;
    top: 0px;
    z-index: 1;

    @media (min-width: $tablet-width) {
      top: 65px;
    }

    .headerWhiteboard {
      background: var(--main-background-color);

      @media (min-width: $tablet-width) {
        padding-top: 3px;
      }

      .messageHeader {
        @include border-bottom-element;
        padding: 12px 16px;

        @media (min-width: $tablet-width) {
          @include border-around-element;
          margin-top: 12px;
        }

        .topHeader {
          display: flex;
          justify-content: space-between;
          align-items: center;
          text-align: left;

          .navMenuIcon {
            font-size: 20px;
            margin-right: 16px;

            @media (min-width: $tablet-width) {
              display: none;
            }
          }

          .titleHeader {
            font-size: 18px;
            font-weight: bold;
            color: var(--primary-text-color);
            text-align: left;
            flex: 1;
          }

          .remindersButton {
            margin-right: 16px;
          }
        }

        .searchContainer {
          margin-top: 8px;
        }
      }

      .messageRequest {
        @include border-around-element;
        padding: 8px 16px;
        margin-top: 8px;
        display: none;
      }
    }

  }

  .container {
    max-width: $content-width;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 16px;

    .spinningContainer {
      text-align: center;
      padding: 16px;
    }
  }
}
