@import "../../../../styles/variables";

.PostGridCard {
  grid-column: span 1;
  padding: 0px !important;
  margin: 0px !important;
  width: 100% !important;
  height: 100%;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  & > span {
    width: 100% !important;
    height: 100% !important;
  }

  .post {
    width: 100%;
    overflow: hidden;
    object-fit: cover;

    @media (min-width: $tablet-width) {
      @include border-element;
    }
  }

  .mediumBackground {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.4;
  }

  .removeContainer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .removeButton {
      border-color: red;
      background: red;
      color: white;
    }
  }
}
