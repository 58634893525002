
.FontModalSource {
  padding: 16px;
  color: var(--primary-text-color);

  @mixin title {
    font-weight: 600;
    font-size: 16px;
  }

  .fontContainer {
    display: flex;
    margin-top: 16px;

    .fontSlider {
      flex: 1;
    }
  }

  .otherOptions {
    margin-top: 20px;
  }

  .actions {
    margin-top: 24px;
  }

  .fontTitle {
    @include title;
  }

  .fontTitle {
    @include title;
  }
}
