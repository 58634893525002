@import "../../../../../styles/variables";

.Header {
  @include border-bottom-element;
  padding: 8px;
  display: flex;
  align-items: center;

  .image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  .supporter {
    flex: 1;
    padding-left: 8px;
  }

  .gameTime {

  }
}
