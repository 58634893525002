@import "../../styles/variables";

.ClubStoryNotes {
  .headerContainer {
    max-width: $content-width;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: $tablet-width) {
      position: sticky;
      top: 65px;
      z-index: 1;
    }

    .headerWhiteboard {
      background: var(--main-background-color);

      @media (min-width: $tablet-width) {
        padding-top: 8px;
      }

      .messageHeader {
        @include border-bottom-element;
        padding: 12px 16px;

        @media (min-width: $tablet-width) {
          @include border-around-element;
          margin-top: 12px;
        }

        .topHeader {
          display: flex;
          align-items: center;

          .goBack {
            margin-right: 8px;
          }

          .titleHeader {
            flex: 1;
            text-align: left;
            font-size: 18px;
            font-weight: bold;

            .sectionId {
              text-transform: capitalize;
            }
          }
        }

        .searchContainer {
          margin-top: 8px;
        }
      }

      .messageRequest {
        @include border-around-element;
        padding: 8px 16px;
        margin-top: 8px;
        display: none;
      }
    }

  }

  .container {
    max-width: $content-width;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 16px;

    .spinningContainer {
      text-align: center;
      padding: 32px;
    }

    .addNewNote {
      width: 100%;
      margin-top: 16px;
    }

    .emptyContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .emptyImage {
        width: 40%;
        margin-top: 16px;
      }

      .emptyButton {
        width: 40%;
        margin-top: 32px;
      }
    }

  }
}
