@import "../../styles/variables";

.StadiumScreen {

  .container {
    @include centered;
    max-width: $content-width;
    padding-top: 16px;
    padding-bottom: 16px;

    .scoreHeader {
      @include border-around-element;
      display: flex;
      align-items: center;
      padding: 8px;

      .scoreContainer {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        .teamIcon1 {
          width: 30px;
          height: 30px;
          object-fit: cover;
          border-radius: 50%;
        }

        .teamScore1 {
          margin-left: 8px;
        }

        .teamScore2 {
          margin-right: 8px;
        }

        .teamIcon2 {
          width: 30px;
          height: 30px;
          object-fit: cover;
          border-radius: 50%;
        }

        .split {
          margin-left: 8px;
          margin-right: 8px;
        }
      }

    }

    .content {
      margin-top: 8px;
    }
  }

}
