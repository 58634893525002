@import "../../../styles/variables";

.ClubTabPaneTab {
  width: calc(calc(100vw - 8px) * (1/2));
  display: flex;
  justify-content: center;
  z-index: 5;

  @media (min-width: $tablet-width) {
    width: calc(756px * (1/2));
    //max-width: calc(calc(#{$content-width} - 40px) * (1/2) )
  }


  &:hover {
    .icon {
      filter: var(--main-filter);
    }
  }

  .icon {
    width: 22px;
    height: auto;
    object-fit: cover;
  }

  .active {
    filter: var(--main-filter);
  }

  .title {
    margin-left: 8px;
    text-transform: capitalize;
  }
}