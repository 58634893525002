@import "../../../styles/variables";

.PostModal {

  .content {

    .image {
      width: 100%;
    }
  }

  .modalTitle {
    display: flex;
    align-items: center;
    padding-left: 16px;
    padding-bottom: 8px;
    padding-top: 8px;

    .image {
      width: 40px;
      height: 40px;
      border-radius: 20px;
    }

    .user {
      flex: 1;
      padding-left: 8px;

      .username {
        color: gray;
        font-size: 14px;
      }
    }
  }

  .footer {
    padding: 0px;
    //background: green;

    .medium {
      display: flex;
      align-items: center;
      height: 60px;
      padding-left: 8px;
      cursor: pointer;

      .image {
        object-fit: cover;
        height: 40px;
        width: 30px;
        background: turquoise;
        border-radius: 2px;
      }

      .title {
        flex: 1;
        padding-left: 8px;
        color: black;
      }

      &:hover {
        .title {
          color: $blue;
        }
      }
    }
  }


}