@import "../../../styles/variables";

.WiltTimelinePreview {

  .header {
    //background: $blue;
    //height: 300px;
    padding: 16px;

    .headerContainer {
      height: 100%;
      display: flex;
      flex-direction: column;

      .title {
        flex: 1;
        font-size: 28px;
        font-weight: 600;
        color: black;
      }

      .headerInfo {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;

        .medium {
          display: flex;
          padding-top: 8px;

          .mediumSubtitle {
            font-size: 16px;
            font-weight: 400;
            color: #4c4c4c;

            .wiltLabel {
              background: yellow;
              color: black;
              padding-left: 4px;
              padding-right: 4px;
              margin-right: 4px;
              font-style: italic;
            }
          }

          .mediumImage {
            height: 70px;
            width: 50px;
          }

          .mediumInfo {
            padding-left: 8px;

            .mediumTitle {
              color: black;
            }

            .mediumAuthor {
              color: #3c3c3c;
            }
          }

        }

        .user {
          display: flex;
          padding-top: 8px;
          padding-bottom: 8px;
          align-items: center;

          .userImage {
            width: 40px;
            height: 40px;
            border-radius: 20px;
            object-fit: cover;
            border-width: 1px;
            border-color: lightgrey;
            border-style: solid;
          }

          .userInfo {
            padding-left: 8px;
            flex: 1;
            color: black;

            .username {
              font-weight: 400;
            }

            .timestamp {
              color: gray;
              font-size: 10px;
            }
          }

          .mediumImage {
            width: 40px;
            height: 50px;
            object-fit: contain;
          }
        }

        .follow {
          margin-left: 16px;
        }
      }

    }
  }

  .body {
    $padding-constant: 16px;
    padding-left: $padding-constant;
    padding-right: $padding-constant;
    padding-bottom: $padding-constant;
    font-size: 1em;

    @media (min-width: $tablet-width) {
      $padding-constant: 16px;
      font-size: 24px;
    }

    .timeline {

      .wiltItem {
        padding-bottom: 24px;

        .wiltContainer {

          .index {
            font-size: 14px;
            color: $tertiaryTextColor;
            font-weight: 800;
          }

          .wiltContent {

            .wiltTitle {
              margin-top: 4px;
              font-weight: 600 !important;
              color: black !important;
              letter-spacing: 0;
              font-size: 20px;
            }

            .wiltBody {
              margin-top: 8px;
              color: black;
              font-size: 16px;
            }
          }

          .wiltEdit {

          }
        }

      }

      .dot {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px !important;
        padding-left: 8px;
      }
    }

    .addPost {
      width: 100%;
    }

    .addDraft {
      width: 100%;
      margin-top: 24px;
    }
  }
}
