@import "../../../styles/variables";

.PreviewQuote {

  .thoughtText {
    width: 100%;
    text-align: left;
    max-width: 400px;
    color: var(--primary-text-color);
    margin-left: auto;
    margin-right: auto;
    padding: 16px 8px;

    @media (min-width: $tablet-width) {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .quoteContainer {
    display: flex;
    justify-content: center;
    border-bottom-color: lightgrey;
    border-bottom-width: 1px;
    border-bottom-style: solid;

    .layersContent {
      $mobileWidth: 100vw;
      height: $mobileWidth;
      width: $mobileWidth;
      border-left-style: solid;
      border-left-width: 1px;
      border-left-color: lightgrey;
      border-right-style: solid;
      border-right-width: 0px;
      border-right-color: lightgrey;
      border-top-style: solid;
      border-top-width: 0px;
      border-top-color: lightgrey;
      position: relative;

      @media (min-width: $mobile-width) {
        $temp: 400px;
        width: 402px;
        height: $temp;
        border-left-width: 1px;
        border-right-width: 1px;
      }

      .quote {
        position: relative;
        width: 100%;
      }
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;

    .navigationBtn {
      text-transform: capitalize;
    }

    .saveAsDraft {
      text-transform: capitalize;
      margin-right: 16px;
    }
  }
}
