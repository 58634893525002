@import "../../styles/variables";

.ChatScreen {
  display: flex;
  flex-direction: column;
  align-items: center;

  .chatHeader {
    min-height: 66px;
    display: flex;
    align-items: center;
    padding: 12px;
    width: 100%;
    max-width: $tablet-width;
    @include border-around-element;

    @media (min-width: $tablet-width) {
      display: flex;
      text-align: left;
      margin-top: 16px;
      align-items: center;
    }

    .content {
      flex: 1;
      display: flex;
      align-items: center;
      padding-left: 16px;
      padding-right: 16px;

      .image {
        height: 40px;
        width: 40px;
        border-radius: 20px;
        overflow: hidden;
        border-style: solid;
        border-width: 1px;
        border-color: lightgrey;

        &:hover {
          cursor: pointer;
        }
      }

      .username {
        font-weight: bold;
        color: black;
        margin-left: 8px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .info {
      font-size: 24px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .container {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-width: $tablet-width;
    height: calc(100vh - 65px);
    display: flex;
    flex-direction: column;

    @media (min-width: $tablet-width) {
      position: relative;
      height: calc(100vh - 172px);
      margin-top: 8px;
      @include border-around-element;
    }

    .membersContainer {
      flex: 1 !important;
      //display: flex !important;
      //flex-direction: column !important;
      overflow-y: auto !important;

      .groupInfo {
        display: flex;
        //justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 16px;

        .groupImage {
          width: 40px;
          height: 40px;
          border-radius: 20px;
          border-style: solid;
          border-width: 1px;
          border-color: lightgrey;
        }

        .nameInput {
          flex: 1;
          margin-left: 16px;
          margin-right: 24px;
        }

        .saveButton {

        }
      }

      .membersTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 16px;

        .title {
          font-weight: bold;
          font-size: 15px;
        }
      }

      .membersList {
        //background: blue;

        .spinningContainer {
          text-align: center;
        }
      }
    }

    .divider {
      height: 1px;
      background: lightgrey;
      width: 100%;
    }

    .dangerButton {
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
    }

    .messagesContainer {
      flex: 1 !important;
      display: flex !important;
      flex-direction: column-reverse !important;
      overflow-y: auto !important;
      padding-bottom: 8px;

      .spinningContainer {
        text-align: center;
        padding-top: 16px;
      }

      .item {
        //background: orange;
        height: auto;
      }
    }

    .inputContainer {
      min-height: 50px;
      border-top-color: lightgrey;
      border-top-width: 1px;
      border-top-style: solid;
      height: auto;
      display: flex;
      align-items: center;
      padding-top: 4px;
      padding-bottom: 4px;
      padding-left: 8px;
      padding-right: 8px;

      .addButton {
        font-size: 22px;
        color: $blue;
        margin-right: 8px;
      }

      .textArea {
        //margin-left: 8px;
        font-size: 16px;
        border-radius: 16px;
      }

      .sendButton {
        margin-left: 8px;
        font-size: 22px;
        color: $blue;
      }
    }
  }
}

.uploadOptionItem {
  display: flex;
  align-items: center;

  .uploadIcon {
    font-size: 50px;
  }

  .uploadText {
    margin-left: 8px;
  }
}

.imageUploadPreview {
  //margin: auto !important;
  margin-left: auto !important;
  margin-right: auto !important;
  display: flex;
  justify-content: center;
  width: 100%;
  //width: 400px;
  //height: 400px;

  .image {
    width: 100% !important;
    object-fit: cover;
  }
}

.modalFooter {
  display: flex;
  align-items: center;
  padding: 8px;

  .textArea {
    //margin-left: 8px;
    font-size: 16px;
    border-radius: 16px;
  }

  .sendButton {
    margin-left: 8px;
    font-size: 22px;
    color: $blue;
  }


}
