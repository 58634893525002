@import "../../../styles/variables";

.StandContent {
  padding-top: 16px;

  .container {

    .headerContainer {
      background: white;

      .header {
        @include border-element;
        padding: 16px;

        .titleContainer {
          display: flex;
          align-items: center;

          .title {
            flex: 1;
            font-size: 17px;
            font-weight: bold;
            text-align: left;
            padding-left: 8px;
          }
        }

        .searchContainer {
          margin-top: 12px;
        }
      }
    }


    .overviewCards {
      height: calc(100vh - 304px);
      overflow-y: auto;

      .standOverviewCard {
        border-left-width: 1px;
        border-left-style: solid;
        border-left-color: lightgrey;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: lightgrey;
        border-right-width: 1px;
        border-right-style: solid;
        border-right-color: lightgrey;
        //background: blue;
        display: flex;
        padding: 16px;
        //margin-top: 8px;

        &:hover {
          cursor: pointer;
        }

        .image {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }

        .textInfo {
          flex: 1;
          padding-left: 8px;

          .titleContainer {
            display: flex;
            justify-content: space-between;

            .title {
              font-weight: bold;
            }
          }

        }

      }

    }

  }
}
