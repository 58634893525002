@import "../../../styles/variables";

.Information {
  text-align: center;
  height: auto;
  @include centered;
  max-width: $max-width;
  margin-top: 32px;
  color: var(--primary-text-color);

  .o1 {
    color: $logoBlue;
  }

  .o2 {
    color: $logoRed;
  }

  .o3 {
    color: $logoGreen;
  }

  .e {
    color: $logoYellow;
  }

  @media (min-width: $tablet-width) {
    margin-top: 64px;
  }

  .informationContainer {

    .title {
      font-size: 26px;
      font-weight: 600;
    }

    .subtitle {
      font-size: 16px;
      font-weight: 500;
      margin-top: 16px;
      margin-bottom: 32px;
      padding-left: 16px;
      padding-right: 16px;
      color: var(--primary-text-color);
    }


    .theWs {
      margin-top: 8px !important;
      display: flex;
      //background: blue;
      //height: 800px;
      flex-direction: column;

      @media (min-width: $tablet-width) {
        margin-top: 80px !important;
        flex-direction: row;
      }

      .item {
        flex: 1;
        padding-left: 40px;
        padding-right: 40px;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 64px;

        @media (min-width: $tablet-width) {
          margin-top: 0px;
        }

        .imageContainer {
          width: 100%;
          //min-height: 400px;
          $temp: 300px;
          $temp1: 100vw;
          //height: 100vw;
          //flex: 1;
          //padding-bottom: 40px;

          @media (min-width: $tablet-width) {
            height: 300px;
          }

          .image {
            width: 100%;
            height: 100%;
            //margin-bottom: 24px;
          }
        }

        .text_title {
          //flex: 1;
          font-size: 26px;
          font-weight: 700;
          color: var(--primary-text-color);;
          //margin-bottom: 24px;
          margin-top: 48px;

          @media (min-width: $tablet-width) {
            margin-top: 0px;
          }
        }

        .text_subtitle {
          //flex: 1;
          font-size: 16px;
          margin-top: 24px;
        }
      }

      .what {
        flex: 1;
      }

      .who {
        flex: 1;
      }
    }
  }
}
