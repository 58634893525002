@import "../../styles/variables";

.ClubStoryCreateNoteScreen {
  .container {
    @include centered;
    @include border-around-element;
    max-width: $content-width;
    padding: 16px;

    @media (min-width: $tablet-width) {
      margin-top: 16px;
    }

    .header {
      display: flex;
      align-items: center;

      .goBack {
        margin-right: 16px;
        display: none;

        @media (min-width: $tablet-width) {
          display: block;
        }
      }

      .name {
        text-transform: capitalize;
        font-size: 18px;
      }
    }

    .form {
      margin-top: 24px;

      .selectItems {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .selectItem {
          flex: 1;
        }

        .locationItem {
          flex: 1;
          display: flex;

          .locationInput {
            width: 100%;
          }

          .switchItem {
            margin-right: 16px;
          }

          .secondsItem {
            margin-left: 16px;
          }
        }
      }

      .rangeContainer {
        display: flex;
        align-items: center;

        .minutesItem {
          margin-right: 8px;
        }

        .startItem {
          display: flex;
          margin-right: 16px;
        }

        .endItem {
          margin-left: 16px;
          display: flex;
        }
      }

      .friendLabel {
        border-color: lightgrey;
        border-width: 1px;
        border-style: solid;
        display: flex;
        align-items: center;
        padding: 4px 8px;
        border-radius: 3px;
        margin-right: 8px;
        margin-top: 2px;
        margin-bottom: 2px;

        .image {
          width: 30px;
          height: 30px;
        }

        .name {
          margin-left: 4px;
        }

        .closeIcon {
          margin-left: 8px;

          &:hover {
            cursor: pointer;
            color: $blue;
          }
        }
      }

    }

    .quoteThoughtContainer {
      display: flex;
    }

    .textAreaContainer {
      flex: 1;

      .titleContainer {
        margin-bottom: 6px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {

        }

      }

      &:first-child {
        margin-right: 16px;
      }

      &:last-child {
        margin-left: 16px;
      }
    }

    .createButton {
      margin-top: 16px;
      text-transform: capitalize;
    }
  }
}