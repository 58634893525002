@import "../../../styles/variables";

.ClubDiscussionsTab {

  .viewAllDiscussionsContainer {
    @include border-bottom-element;
    padding-left: 16px;
    padding-right: 16px;

    @media (min-width: $tablet-width) {
      padding-left: 0px;
      padding-right: 0px;
      border-bottom-width: 0px;
    }

    .viewAllDiscussions {
      width: 100%;
      margin-top: 16px;
      margin-bottom: 16px;
    }

  }
}