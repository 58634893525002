@import "../../../styles/variables";

.StoryTemplateCard {
  @include border-around-element;
  display: flex !important;
  //justify-content: space-between;
  padding: 8px;
  align-items: center;
  margin-top: 8px;

  .usesContainer {
    text-align: center;
    padding-right: 12px;

    .usesTitle {
      font-size: 10px;
    }
  }

  .info {
    flex: 1;

    .title {
      font-weight: bold;
      font-size: 16px;
    }

    .userContainer {
      display: flex;
      align-items: center;
      margin-top: 8px;

      .userImage {
        width: 24px;
        height: 24px;
        border-radius: 12px;
      }

      .username {
        padding-left: 8px;
      }
    }

  }
}
