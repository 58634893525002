@import "../../../styles/variables";

.content {

  .title {
    font-size: 18px;
    font-weight: bold;
  }

  .filterSearchContainer  {
    display: flex;
    align-items: center;
    margin-top: 8px;

    .filtersButton {
      margin-left: 8px;
      text-transform: capitalize;
    }
  }

  .filtersTitle {
    margin-top: 16px;
    font-size: 12px;
    color: gray;
  }

  .filtersContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .filterContainer {

      .filterTitle {
        font-size: 12px;
      }
    }

  }

  .templatesBody {
    margin-top: 16px;
    height: 240px;
    overflow-y: auto;

    .spinningContainer {
      text-align: center;
      padding-top: 16px;
    }

    .loadingContainer {

      .loadingCard {
        @include border-around-element;
        padding: 8px;
        margin-bottom: 16px;
      }
    }

    .nothingContainer {
      width: 100%;
      padding: 32px;
      text-align: center;
    }
  }

  .createButton {
    width: 100%;
    margin-top: 16px;
  }

}

.displayNote {
  display: flex;
  flex-direction: column;
  align-items: center;

  .container {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-width: $tablet-width;
    height: calc(100vh - 65px);
    display: flex;
    flex-direction: column;

    @media (min-width: $tablet-width) {
      position: relative;
      height: calc(100vh - 172px);
      margin-top: 8px;
    }

    .header {
      padding: 16px;

      .titleContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
          flex: 1;
          text-align: left;
          font-size: 17px;
          font-weight: bold;
        }
      }

      .subtitle {
        display: flex;

        .location {
          margin-right: 8px;
        }

        .categories {
          display: flex;

          .category {
            margin-right: 8px;
          }
        }
      }
    }

    .loadingHeader {
      padding: 16px;

      .loadingItem {
        width: 100%;
      }
    }

    .messagesContainer {
      border-top-color: lightgrey;
      border-top-width: 1px;
      border-top-style: solid;
      flex: 1 !important;
      display: flex !important;
      flex-direction: column !important;
      overflow-y: auto !important;
      padding-bottom: 8px;

      .loadingContent {
        padding: 16px;
      }

      .text {
        padding: 16px;
        white-space: pre-line;
      }

      .quoteThoughtContainer {
        padding: 16px;
        display: flex;

        .quoteContainer {
          flex: 1;

          .title {
            font-weight: 500;
            text-transform: capitalize;
          }

          .body {
            margin-top: 16px;
            white-space: pre;
          }
        }
      }

      .questionAnswerContainer {
        padding: 16px;

        .questionContainer {
          .title {
            font-weight: 500;
            text-transform: capitalize;
          }

          .body {
            margin-top: 16px;
            white-space: pre;
          }
        }

        .answerContainer {
          margin-top: 16px;

          .showAnswer {
            text-transform: capitalize;
          }

          .title {
            font-weight: 500;
            text-transform: capitalize;
          }

          .body {
            margin-top: 16px;
            white-space: pre;
          }
        }
      }

      .spinningContainer {
        text-align: center;
        padding-top: 16px;
      }

      //.item {
      //  //background: orange;
      //  height: auto;
      //}
    }

    //.inputContainer {
    //  min-height: 50px;
    //  border-top-color: lightgrey;
    //  border-top-width: 1px;
    //  border-top-style: solid;
    //  height: auto;
    //  display: flex;
    //  align-items: center;
    //  padding: 4px 8px;
    //
    //}
  }
}
