@import "../../../../styles/variables";

.HowItWorksItem {
  width: 100%;
  display: flex;
  align-items: center;
  color: var(--primary-text-color);;
  margin-top: 64px;
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: column;

  @media (min-width: $tablet-width) {
    flex-direction: row;
  }

  &:nth-child(even) {
    //flex-direction: row;

    @media (min-width: $tablet-width) {
      flex-direction: row-reverse;

      .textContainer {
        padding-right: 0px;
        padding-left: 100px;
        text-align: right;
      }
    }
  }

  .textContainer {
    flex: 1;
    padding-right: 0px;

    @media (min-width: $tablet-width) {
      padding-right: 100px;
    }

    .step {
      font-size: 16px;
      font-weight: 600;
      color: gray;
    }

    .title {
      font-size: 24px;
      font-weight: 600;
    }

    .subtitle {
      margin-top: 24px;
    }

    .actions {
      margin-top: 24px;
    }
  }

  .imageContainer {
    flex: 1;

    .image {
      width: 100%;
      padding-top: 40px;

      @media (min-width: $tablet-width) {
        padding-top: 0px;
      }
    }
  }
}
