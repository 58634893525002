
.NotesSourceModal {

}

.content {

  .title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 32px;
  }

  .optionButton {
    width: 100%;
    margin-bottom: 16px;
  }
}