@import "../../../styles/variables";

.WiltCard2 {
  background: var(--main-background-color);
  overflow: hidden;
  @include border-top-bottom;
  margin-bottom: $home-feed-card-margin-bottom !important;
  width: 100%;
  flex-direction: column;

  @media (min-width: $tablet-width) {
    margin: 16px auto;
    border-radius: 4px;
    border-style: solid;
    border-color: lightgrey;
    border-width: 1px;
    border-bottom-width: 1px !important;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px 16px;


    .profileImage {
      height: 32px;
      width: 32px;
      border-radius: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      object-fit: cover;
      border-color: lightgrey;
      border-style: solid;
      border-width: 1px;
    }

    .username {
      padding-left: 12px;
    }
  }

  .title {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: var(--main-home-divider-color);
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 8px;
    width: 100%;
    font-size: 15px;
    color: var(--primary-text-color);
  }

  .body {
    min-height: auto;
    max-height: 100vw;
    overflow: hidden;
    object-fit: cover;
    display: flex;
    flex-direction: column;

    @media (min-width: $tablet-width) {
      //height: $post-width;
      min-height: auto;
      max-height: $post-width;
      width: 100%;
    }

    .wiltTitleContainer {
      height: 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-top: 4px;
      padding-left: 12px;
      padding-right: 12px;

      .wiltLabel {
        font-style: italic;
        //background: linear-gradient(180deg,rgba(255,255,255,0) 0%, rgba(255, 255, 0, 0.9) 100%);
        background: yellow;
        padding-left: 2px;
        padding-right: 2px;
        font-size: 12px;
        color: black;

        @media (min-width: $tablet-width) {
          padding-left: 4px;
          padding-right: 4px;
          font-size: 14px;
        }
      }
    }

    .wiltContainer {
      flex: 1;
      overflow-y: hidden;
      padding: 16px;
      color: black;
      font-size: 18px;
      font-weight: 500;
    }

    .viewMore {
      color: $blue;
      font-size: 14px;
      font-weight: 500;
      padding-top: 8px;
      padding-bottom: 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        cursor: pointer;
      }
    }

  }

  .source {
    width: 100%;
    border-top-color: var(--main-home-divider-color);
    border-top-width: 1px;
    border-top-style: solid;
    border-bottom-color: var(--main-home-divider-color);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .sourceInfo {
      display: flex;
      align-items: center;

      &:hover {

        .sourceImage {
          cursor: pointer;
        }

        .sourceTitle {
          cursor: pointer;
          color: $blue !important;
        }
      }

      .sourceImage {
        height: 40px;
        width: 30px;
        object-fit: contain;
      }

      .sourceTitle {
        padding-left: 8px;
        color: black;
        font-weight: 500;
      }

    }

  }

  .footer {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 8px;

    .actions {
      font-size: 26px;

      .likedIcon {
        color: red;
      }

      .defaultIcon {

      }
    }

    .stats {
      color: black;
      font-weight: 600;
    }

    .title {
      .titleUsername {
        color: black;
        font-weight: 600;
      }

      .titleText {
        padding-left: 8px;
      }
    }

    .timeStamp {
      margin-top: 4px;
      font-size: 12px;
    }
  }

}
