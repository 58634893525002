
.TemplatesTab {

}


.TemplateModal {

  .content {

    .previewHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .previewTitle {
        font-size: 18px;
        font-weight: bold;
      }

    }

    .previewInfo {
      margin-bottom: 16px;
    }

    .previewBody {
      max-height: 400px;
      overflow-y: auto;
      //margin-top: 16px;

      .chapter {

        .info {
          display: flex;
          margin-bottom: 8px;

          .number {
            font-weight: bold;
            padding-right: 4px;
          }

          .chapterInput {
            margin-left: 8px;
          }
        }

        .subChapterSection {

          .subChapter {
            padding-left: 16px;

            .subInfo {
              display: flex;
              //align-items: center;
              margin-bottom: 8px;

              .subNumber {
                //font-size: 21px;
                font-weight: bold;
                padding-right: 4px;
              }

              .location {
                color: gray;
                padding-left: 8px;
                font-style: italic;
              }
            }
          }
        }

        .actions {
          display: flex;
          justify-content: flex-end;

          .addSubchapter {
            margin-bottom: 16px;
          }
        }

      }
    }

    .previewFooter {
      margin-top: 16px;
      display: flex;
      justify-content: space-between;

      .useButton {
        width: 100%;
      }
    }
  }
}