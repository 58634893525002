@import "../../../styles/variables";

.MediumCard {
  border-bottom-style: solid !important;
  border-bottom-width: 1px !important;
  border-bottom-color: lightgrey !important;
  padding: 16px !important;
  cursor: pointer;

  @media (min-width: $tablet-width) {
    @include border-around-element;
    margin-top: 16px;
  }
}
