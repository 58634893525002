@import "../../../../../styles/variables";

.CardCard {
  @include border-around-element;
  width: 280px;
  min-height: 200px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;

  .body {
    flex: 1;
  }
}
