
.Summary {
  padding-top: 16px;
  padding-bottom: 16px;

  .title {
    padding-left: 16px;
    padding-right: 16px;

    .summaryInputTitle {
      font-weight: normal;
    }
  }

  .body {

    .textItem {
      margin-bottom: 16px;
    }

  }

  .addTextItem {
    padding-top: 8px;
    display: flex;
    justify-content: space-between;
  }
}
