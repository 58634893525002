@import "../../../../../styles/variables";

.SubstitutionCard {
  @include border-around-element;
  width: 280px;
  min-height: 200px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;

  .body {
    padding: 12px;

    .player {
      display: flex;
      align-items: center;
      margin-top: 8px;

      .info {
        flex: 1;

        .in {
          font-size: 11px;
          color: green;
        }

        .out {
          font-size: 11px;
          color: red;
        }

        .name {
          font-weight: bold;
        }

        .detailsContainer {
          display: flex;

          .detailsImage {
            width: 20px;
            height: 20px;
            border-radius: 50%;
          }

          .detailsText {
            padding-left: 8px;
          }
        }
      }

      .image {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }
  }
}
