@import "../../styles/variables";

.ThumbNailScreen {

  .contentContainer {
    display: flex;
    flex-direction: column;

    @media (min-width: $tablet-width) {
      flex-direction: row;
    }

    .controls {
      flex: 1;
      max-width: 400px;
      display: flex;
      flex-direction: column;
      padding-left: 16px;
      padding-right: 16px;

      @media (min-width: $tablet-width) {
        //margin-left: 16px;
      }

      .controlButton {
        margin-top: 16px;
        text-align: center;
      }
    }
  }

}
