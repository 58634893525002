@import "../../../styles/variables";

.OverViewCard {
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom-style: solid !important;
  border-bottom-width: 1px !important;
  border-bottom-color: lightgrey !important;


  @media (min-width: $tablet-width) {
    @include border-around-element;
    margin-top: 12px;
  }

  &:hover {
    cursor: pointer;
  }

  .image {
    width: auto;
    height: 80px;
    object-fit: contain;
    text-align: left;
  }

  .textContainer {
    padding-left: 12px;
    width: 100%;
    //white-space: normal;

    .message {
      width: 100%;
      margin: 0px;
    }

    .chatRoomInfo {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .username {
        flex: 1;
        font-weight: 700;
      }

      .messagesCount {

      }
    }


  }
}
