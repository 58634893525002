@import "../../../../styles/variables";

.TemplatesItem {
  width: 260px;
  height: 260px;
  //background: red;
  margin-right: 24px;
  padding: 16px;

  &:last-child {
    margin-right: 0px;
  }

  .container {
    //background: blue;
    height: 100%;
    display: flex;
    flex-direction: column;

    .title {
      padding-left: 4px;
      color: var(--primary-text-color);
      font-size: 16px;
      font-weight: 500;
    }

    .preview {
      margin-top: 4px;
      //height: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;

      .previewContainer {
        flex: 1;
      }

    }
  }

  .active {
    border-color: $blue;
    border-width: 2px;
    border-style: solid;
    border-radius: 4px;
    padding: 4px;
  }
}
