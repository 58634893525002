
.ThumbTestFin {
  width: 800px;
  height: 600px;
  margin-top: 16px;
  overflow-y: auto;
  display: grid;
  grid-gap: 2px;
  grid-template-columns: 1fr 60% 1fr;
  grid-template-rows: 1fr 40% 1fr;
  grid-template-areas:
               "section8 section1  section2"
               "section7 court section3"
               "section6 section5 section4";
  color: #444;
  //transform: rotate(90deg);
  $standColor: #bcbcbc;

  .section1 {
    grid-area: section1;
    background: $standColor;
    display: grid;
    padding: 16px;
    grid-gap: 16px;
    grid-template-columns: repeat(3, 1fr);

    .subsection1 {
      background: orange;
      border-radius: 4px;
      padding: 16px;
    }
  }

  .section2 {
    grid-area: section2;
    background: $standColor;
    border-top-right-radius: 80%;
  }

  .section3 {
    grid-area: section3;
    background: $standColor;
    display: grid;
    padding: 16px;
    grid-gap: 16px;
    grid-template-columns: repeat(1, 1fr);

    .subsection3 {
      background: orange;
      border-radius: 4px;
      padding: 16px;
    }
  }

  .section4 {
    grid-area: section4;
    background: $standColor;
    border-bottom-right-radius: 80%;
  }

  .section5 {
    grid-area: section5;
    background: $standColor;
    display: grid;
    padding: 16px;
    grid-gap: 16px;
    grid-template-columns: repeat(2, 1fr);

    .subsection5 {
      background: orange;
      border-radius: 4px;
      padding: 16px;
    }
  }

  .section6 {
    grid-area: section6;
    background: $standColor;
    border-bottom-left-radius: 80%;
  }

  .section7 {
    grid-area: section7;
    background: $standColor;
    display: grid;
    padding: 16px;
    grid-gap: 16px;
    grid-template-columns: repeat(1, 1fr);

    .subsection7 {
      background: orange;
      border-radius: 4px;
      padding: 16px;
    }

  }

  .section8 {
    grid-area: section8;
    background: $standColor;
    border-top-left-radius: 80%;
    overflow: hidden;
  }

  .court {
    grid-area: court;
    background: burlywood;
  }



}