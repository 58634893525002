@import "../../../../styles/variables";

.Highlights {
  background: var(--main-background-color);
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
  width: 100%;
  height: 100%;
  padding: 8px;
  color: var(--primary-text-color);

  .container {
    height: 100%;
    display: flex;
    flex-direction: column;

    .title {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      align-content: center;
    }

    .source {

    }
  }
}
