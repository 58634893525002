@import "../../../styles/variables";

.ListInfo {
  padding: 16px;

  .friendLabel {
    border-color: lightgrey;
    border-width: 1px;
    border-style: solid;
    display: flex;
    align-items: center;
    padding: 4px 8px;
    border-radius: 3px;
    margin-right: 8px;
    margin-top: 2px;
    margin-bottom: 2px;

    .image {
      width: 30px;
      height: 30px;
      border-radius: 15px;
    }

    .name {
      margin-left: 4px;
    }

    .closeIcon {
      margin-left: 8px;

      &:hover {
        cursor: pointer;
        color: $blue;
      }
    }
  }
}

.friendLabelOption {
  display: flex !important;
  align-items: center;
  padding: 4px 8px;

  .image {
    width: 30px !important;
    height: 30px !important;
    border-radius: 15px !important;
  }

  .name {
    margin-left: 4px;
  }
}