@import "../../styles/variables";

.ClubsScreen {

  .headerContainer {
    max-width: $content-width;
    margin-left: auto;
    margin-right: auto;
    position: sticky;
    top: 0px;
    z-index: 1;

    @media (min-width: $tablet-width) {
      top: 65px;
    }

    .headerWhiteboard {
      background: var(--main-background-color);

      @media (min-width: $tablet-width) {
        padding-top: 3px;
      }

      .messageHeader {
        @include border-bottom-element;
        padding: 12px 16px;

        @media (min-width: $tablet-width) {
          @include border-around-element;
          margin-top: 12px;
        }

        .topHeader {
          display: flex;
          align-items: center;


          .navMenuIcon {
            font-size: 20px;
            margin-right: 16px;

            @media (min-width: $tablet-width) {
              display: none;
            }
          }

          .goBack {
            margin-right: 8px;
          }

          .titleHeader {
            flex: 1;
            text-align: left;
            font-size: 18px;
            font-weight: bold;
            color: var(--primary-text-color);

            .sectionId {
              text-transform: capitalize;
            }
          }
        }

        .searchContainer {
          margin-top: 8px;
        }
      }

      .messageRequest {
        @include border-around-element;
        padding: 8px 16px;
        margin-top: 8px;
        display: none;
      }
    }

  }

  .container {
    max-width: $content-width;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 16px;

    .spinningContainer {
      grid-column: span 3;
      text-align: center;
      padding-top: 16px;
    }

    .gridList {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-auto-rows: 180px;
      grid-column-gap: 2vw;
      grid-row-gap: 2vw;

      @media (min-width: $mobile-width) {
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 264px;
      }

      @media (min-width: $tablet-width) {
        padding-top: 16px;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 240px;
        padding-left: 0px;
        padding-right: 0px;
      }

      .skeletonItem {
        grid-column: span 1;
        width: 100%;
        height: 100%;
        bottom: 0;
      }
    }
  }
}
