@import "../../../../styles/variables";

.PodcastEpisodeCard {
  border-bottom-width: 1px !important;
  border-bottom-style: solid !important;
  border-bottom-color: lightgrey !important;
  padding: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;

  @media (min-width: $tablet-width) {
    @include border-around-element;
    margin-top: 16px;
  }

  &:hover {
    cursor: pointer;
  }

  .dateContainer {
    text-align: center;

    .day {
      font-size: 16px;
      font-weight: 600;
    }

    .month {
      font-size: 10px;
    }

    .year {
      font-size: 8px;
    }
  }

  .infoContainer {
    margin-left: 16px;

    .title {
      color: var(--primary-text-color);
    }

    .description {
      color: gray;
    }
  }

  .moreContainer {

  }
}
