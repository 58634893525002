@import "../../../../../styles/variables";

.GoalCard {
  @include border-around-element;
  width: 280px;
  min-height: 200px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
}
