@import "../../../styles/variables";

.blue {
  background: $blue !important;
  border-left-width: 3px !important;;
  border-left-color: $blue !important;
  border-left-style: solid;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.yellow {
  background: $logoYellow !important;
  border-left-width: 3px !important;
  border-left-color: $logoYellow !important;
  border-left-style: solid;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.green {
  background: $green !important;
  border-left-width: 3px !important;;
  border-left-color: $green !important;
  border-left-style: solid;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.hideAround {
  @media (min-width: $tablet-width) {
    border-width: 1px;
    border-style: solid;
    border-color: lightgrey;
    border-radius: 4px;
    margin-top: 16px;
  }
}

.borderAround {
  @include border-bottom-element;

  @media (min-width: $tablet-width) {
    border-width: 1px;
    border-style: solid;
    border-color: lightgrey;
    border-radius: 4px;
    margin-top: 16px;
  }
}

.NotesCard {
  //@include border-bottom-element;
  overflow-y: hidden;

  @media (min-width: $tablet-width) {
    //margin-top: 16px;
    //@include border-around-element;
  }

  .backgroundContainer {
    //padding-left: 6px;
    display: flex;

    @media (min-width: $tablet-width) {
      padding-left: 4px;
    }

    .container {
      flex: 1;
      display: flex;
      background: var(--main-background-color);
      padding: 8px 16px 8px 12px;

      @media (min-width: $tablet-width) {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }

      .locationContainer {
        text-align: center;
        margin-top: auto;
        margin-bottom: auto;

        .value {
          font-size: 16px;
        }

        .title {
          font-size: 12px;
          color: gray;
        }
      }

      .infoContainer {
        flex: 1;
        padding-left: 16px;
        //width: 90%;

        & > div {
          margin-bottom: 0 !important;
        }

        &:hover {
          cursor: pointer;
        }

        .titleCloseContainer {
          display: flex;
          justify-content: space-between;
          align-items: center;

          & > div {
            margin-bottom: 0 !important;
          }
        }

        .message {
          font-weight: bold;
          white-space: pre-line;
        }

        .subtitle {
          overflow-y: hidden;
          white-space: pre-line;
        }

        .footer {
          margin-top: 8px;
          display: flex;

          .tags {
            flex: 1;
          }
        }

      }

    }
  }

}
