@import "../../../styles/variables";

.OfficialCardCard {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: lightgrey;

  &:hover {
    cursor: pointer;
  }

  .officialContent {

    .imageContainer {
      padding-top: 16px;
      text-align: center;

      .card {
        @include centered;
        height: 28px;
        width: 20px;
        border-radius: 5px;
        margin-top: 12px;
      }

      .red {
        background: red;
      }

      .yellow {
        background: yellow;
      }

      .green {
        background: green;
      }

      .image {
        width: 24px;
        height: 24px;
        object-fit: cover;
      }
    }

    .title {
      font-weight: bold;
      text-align: center;
      margin-top: 4px;
      text-transform: capitalize;
    }

    .time {
      text-align: center;
      margin-top: 4px;
    }

    .players {
      display: flex;
      @include centered;
      align-items: center;
      justify-content: center;
      //max-width: 340px;

      .player {
        display: flex;
        align-items: center;
        margin-top: 8px;

        .info {
          flex: 1;

          //.in {
          //  font-size: 11px;
          //  color: green;
          //}

          .out {
            font-size: 11px;
            color: red;
          }

          .name {
            font-weight: bold;
          }

          .detailsContainer {
            display: flex;

            .detailsImage {
              width: 20px;
              height: 20px;
              border-radius: 50%;
            }

            .detailsText {
              padding-left: 8px;
            }
          }
        }

        .image {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          margin-left: 16px;
        }
      }
    }
  }

  .footerContainer {
    display: flex;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;

    .reactContainer {
      padding-left: 8px;
      padding-right: 16px;

      .reactionImage {
        width: 24px;
        height: 24px;
        object-fit: cover;
        margin-bottom: -2px;
      }
    }

    .footer {
      flex: 1;
      margin-top: 8px;
      display: flex;
      justify-content: space-between;

      .leftStats {
        display: flex;
        align-items: center;

        .reactionIcon1 {
          width: 18px;
          height: 18px;
          border-radius: 50%;
        }

        .reactionIcon2 {
          width: 18px;
          height: 18px;
          border-radius: 50%;
        }

        .reactionIcon3 {
          width: 18px;
          height: 18px;
          border-radius: 50%;
        }
      }

      .rightStats {
        display: flex;
        align-items: center;

        .shareIcon {
          width: 18px;
          height: 18px;
          border-radius: 50%;
        }

        .commentNumber {
          margin-right: 8px;
        }

        .commentIcon {
          width: 18px;
          height: 18px;
          border-radius: 50%;
        }
      }
    }
  }
}
