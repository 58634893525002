@import "src/styles/variables";

.NewTemplateScreen {

  .container {
    width: 100%;
    max-width: $tablet-width;
    padding: 16px;

    .header {
      display: flex;
      align-items: center;

      .name {
        margin-left: 16px;
        font-size: 18px;
      }
    }

    .form {

      .locationContainer {
        display: flex;
        align-items: flex-end;

        .versionInput {
          width: 120px;
        }

        .audioContainer {
          margin-left: 16px;
        }
      }

      .friendLabel {
        border-color: lightgrey;
        border-width: 1px;
        border-style: solid;
        display: flex;
        align-items: center;
        padding: 4px 8px;
        border-radius: 3px;
        margin-right: 8px;
        margin-top: 2px;
        margin-bottom: 2px;

        .image {
          width: 30px;
          height: 30px;
          border-radius: 15px;
        }

        .name {
          margin-left: 4px;
        }

        .closeIcon {
          margin-left: 8px;

          &:hover {
            cursor: pointer;
            color: $blue;
          }
        }
      }

      .chapter {

        .info {
          display: flex;
          align-items: center;
          margin-bottom: 16px;

          .number {
            font-size: 21px;
            font-weight: bold;
          }

          .chapterInputGroup {
            margin-left: 8px;
            display: flex;

            .startMinute {
              width: 100px;
            }

            .endMinute {
              width: 100px;
            }
          }
        }

        .subChapterSection {

          .subChapter {
            padding-left: 28px;

            .subInfo {
              display: flex;
              align-items: center;
              margin-bottom: 16px;

              .subNumber {
                font-size: 21px;
                font-weight: bold;
                padding-right: 4px;
              }

              .subChapterInputGroup {
                display: flex;

                .startMinute {
                  width: 100px;
                }

                .endMinute {
                  width: 100px;
                }
              }
            }
          }
        }

        .actions {
          display: flex;
          justify-content: flex-end;

          .addSubchapter {
            margin-bottom: 16px;
          }
        }

      }

      .addChapter {
        width: 100%;
        margin-bottom: 16px;
      }

      .createButton {
      }
    }
  }

}

.friendLabelOption {
  display: flex !important;
  align-items: center;
  padding: 4px 8px;

  .image {
    width: 30px !important;
    height: 30px !important;
    border-radius: 15px !important;
  }

  .name {
    margin-left: 4px;
  }
}

