@import "../../../styles/variables";

.HowItWorks {
  margin-top: 60px;
  @include centered;
  max-width: $max-width;
  color: var(--primary-text-color);

  @media (min-width: $tablet-width) {
    margin-top: 120px;
  }

  .title {
    font-size: 26px;
    font-weight: 500;
    text-align: center;
    padding-left: 16px;
    padding-right: 16px;

    @media (min-width: $tablet-width) {
      font-size: 42px;
    }
  }

  .subtitle {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 32px;
    text-align: center;
    padding-left: 16px;
    padding-right: 16px;
    color: var(--primary-text-color);
  }

  .container {
    margin-top: 40px;
  }
}
