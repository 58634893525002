@import "../../styles/variables";

.InterestModal {

  .content {


    .header {
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-bottom-color: lightgrey;
      padding: 16px 8px;

      .progressContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .title {
        //color: black;
        font-weight: 700;
        font-size: 18px;
      }

      .logoTitle {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 700;
      }

      .subtitle {
        //color: black;
        font-weight: 500;
        font-size: 17px;
      }
    }

    .interests {
      margin-top: 16px;
      height: 320px;
      overflow: auto;
      padding-left: 8px;
      padding-right: 8px;
    }

    .footer {
      border-top-width: 1px;
      border-top-style: solid;
      border-top-color: lightgrey;
      padding: 16px 8px;
      display: flex;
      justify-content: space-between;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      .nextButton {
        font-weight: normal;
      }

      .skipButton {
        font-weight: normal;
      }
    }

    .imageChoice {
      width: 140px;
    }
  }

  .ant-modal-body {
    padding: 0;
  }
}

.ant-modal-body {
  padding: 0 !important;
}
