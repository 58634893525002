@import "../../../styles/variables";

.Welcome {
  padding: 16px;
  text-align: center;

  .image {
    width: 100%;
  }

  .welcomeText {
    margin-top: 16px;
    font-size: 24px;
    font-weight: 600;
    //color: black;
  }

  .subtitle {
    margin-top: 16px;
    //color: black;
  }

  .yesButton {
    margin-top: 32px;
  }

  .skipButton {
    margin-top: 16px;
  }
}
