@import "../../../styles/variables";

.RequestCard {
  @include border-around-element;
  padding: 12px;
  display: flex;
  margin-bottom: 12px;
  flex-direction: column;

  @media (min-width: $tablet-width) {
    flex-direction: row;
    align-items: center;
  }

  .userContainer {
    display: flex;
    align-items: center;
    flex: 1;

    .image {
      width: 50px;
      height: 50px;
      border-radius: 25px;

      &:hover {
        cursor: pointer;
      }
    }

    .usernameContainer {
      flex: 1;
      padding-left: 12px;
      color: var(--primary-text-color);

      .username {

        &:hover {
          cursor: pointer;
        }
      }

    }

  }

  .actions {
    margin-top: 8px;

    @media (min-width: $tablet-width) {
      margin-top: 0px;
    }

    .confirmButton {
      margin-right: 16px;
    }
  }

}
