@import "../../../../../styles/variables";

.PollCard {
  @include border-around-element;
  width: 280px;
  min-height: 200px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;

  .body {
    flex: 1;
    padding: 8px;

    .question {

    }

    .resultContainer {
      //padding-top: 8px;

      .option {
        display: flex;
        align-items: center;
        margin-top: 16px;

        .image {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }

        .percentage {
          margin-left: 8px;
        }
      }

      .option2 {
        display: flex;
        align-items: center;
        margin-top: 16px;

        .image {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }
      }

    }


  }

}
